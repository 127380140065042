import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: "Roboto";
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
  }

  h1 {
    color: #5A5B5F;
    font-weight: normal;
    margin-bottom: 10px;
  }

  .out-of-stock{
    font-size: 14px;
    color: #ff0000;
    font-style: italic;
  }

  .financial-discount {
    font-size: 14px;
    color: #008000;
  }

  .minimumNotReached {
    color: #f00;
  }

  .handshake {
    margin: 0 40px;
  }

  .handshakeInternal {
    margin: 0 10px;
  }

  button {
    border-radius: 15px;
    background: #7EB9CD;
    padding: 10px;
    color: #fff;
    border: 0;
    font-size: 15px;
    font-weight: bold;
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
    cursor: pointer;

    :disabled {
      color: rgba(255,255,255,0.5);
    }
  }

  .toast {
    a {
      color: #fff;
      text-decoration: none;
    }
  }

  .modal {
    background: #fff;
    border-radius: 5px;
    width: 50%;
    outline: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .head {
      display: flex;
      background: #213880;
      border-radius: 5px 5px 0 0;

      h2 {
        flex: 1;
        text-align: center;
        font-size: 0.9em;
        color: #fff;
        margin: 0;
        padding: 20px;
      }

      button {
        margin: 5px;
        color: #AFBDE6;
      }
    }

    .content {
      padding: 10px;
      text-align: center;

      p {
        margin-top: 15px;
        color: #666;
      }
    }
  }

  ul.pagination {
    display: inline-block;
    padding: 0 15px;
    text-align: center;

    li {
      display: inline-block;

      a {
        padding: 8px 16px;
        user-select: none;
        text-decoration: none;
        cursor: pointer;
        border-radius: 4px;

        &:hover {
          background-color: #ddd;
        }
      }

      &.selected a {
        background-color: #213880;
        color: white;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  };

  .fadein {
    animation: fadeIn 0.5s linear;
  }
`;

export default GlobalStyle;
