import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as KitActions } from '../../store/ducks/kit';
import { ModalTable, ProductNameColumn, KitProductLine } from './styles';

class ModalContent extends Component {
  state = {
  }

  static propTypes = {
    loading: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      codigo: PropTypes.string.isRequired,
      nome: PropTypes.string.isRequired,
      quantidade: PropTypes.string.isRequired,
      desconto: PropTypes.string.isRequired,
      preco: PropTypes.string.isRequired,
      formattedDesconto: PropTypes.string.isRequired,
      formattedPreco: PropTypes.string.isRequired,
    })).isRequired,
    product: PropTypes.shape({
      uf_kit: PropTypes.arrayOf(PropTypes.shape({
        uf: PropTypes.string.isRequired,
        cd_kit: PropTypes.number.isRequired,
      })).isRequired,
    }).isRequired,
    kitRequest: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { kitRequest, product } = this.props;

    kitRequest({ ufKit: product.uf_kit });
  }

  render() {
    const { loading, data } = this.props;

    return (
      <div className="content">
        <ModalTable>
          <thead>
            <tr>
              <th>Cód.</th>
              <th>Mercadoria</th>
              <th>Qtd</th>
              <th>Desc (%)</th>
              <th>Preço</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={5}>Carregando produtos...</td>
              </tr>
            ) : data.map(product => (
              <KitProductLine key={product.codigo}>
                <td>{product.codigo}</td>
                <ProductNameColumn>{product.nome}</ProductNameColumn>
                <td>{product.quantidade}</td>
                <td>{product.formattedDesconto}</td>
                <td>{product.formattedPreco}</td>
              </KitProductLine>
            ))}
          </tbody>
        </ModalTable>
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => bindActionCreators(KitActions, dispatch);

const mapStateToProps = state => ({
  loading: state.kit.loading,
  data: state.kit.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalContent);
