export const Types = {
  PRODUCT_REQUEST: 'product/PRODUCT_REQUEST',
  PRODUCT_SUCCESS: 'product/PRODUCT_SUCCESS',
  PRODUCT_FAILURE: 'product/PRODUCT_FAILURE',
  PRODUCT_SET_SEARCH_TERM: 'product/PRODUCT_SET_SEARCH_TERM',
  PRODUCT_SET_SEARCH_INDUTRY: 'product/PRODUCT_SET_SEARCH_INDUTRY',
  PRODUCT_SET_SEARCH_PRODUCT_TYPE: 'product/PRODUCT_SET_PRODUCT_TYPE',
  PRODUCT_SET_SEARCH_CATEGORY_GROUP: 'product/PRODUCT_SET_CATEGORY_GROUP',
  PRODUCT_SET_SEARCH_CATEGORY: 'product/PRODUCT_SET_CATEGORY',
};

const INITIAL_STATE = {
  loading: true,
  data: [],
  searchTerm: '*',
  searchIndustry: '*',
  searchProductType: '*',
  searchCategoryGroup: '*',
  searchCategory: '*',
  error: null,
  total: 0,
  offset: 0,
  limit: 100,
};

export default function product(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.PRODUCT_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
        offset: action.payload.offset,
      };
    case Types.PRODUCT_SUCCESS:
      return {
        ...state,
        data: action.payload.data.rows,
        total: action.payload.data.count,
        loading: false,
        error: null,
      };
    case Types.PRODUCT_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.message,
      };
    case Types.PRODUCT_SET_SEARCH_TERM:
      return { ...state, searchTerm: action.payload.term, offset: action.payload.offset };
    case Types.PRODUCT_SET_SEARCH_INDUTRY:
      return { ...state, searchIndustry: action.payload.industry, offset: action.payload.offset };
    case Types.PRODUCT_SET_SEARCH_PRODUCT_TYPE:
      return {
        ...state,
        searchProductType: action.payload.productType,
        offset: action.payload.offset,
      };
    case Types.PRODUCT_SET_SEARCH_CATEGORY_GROUP:
      return {
        ...state,
        searchCategoryGroup: action.payload.categoryGroup,
        offset: action.payload.offset,
      };
    case Types.PRODUCT_SET_SEARCH_CATEGORY:
      return {
        ...state,
        searchCategory: action.payload.category,
        offset: action.payload.offset,
      };
    default:
      return state;
  }
}

export const Creators = {
  productRequest: (payload = null) => ({
    type: Types.PRODUCT_REQUEST,
    payload,
  }),

  productSuccess: data => ({
    type: Types.PRODUCT_SUCCESS,
    payload: { data },
  }),

  productFailure: message => ({
    type: Types.PRODUCT_FAILURE,
    payload: { message },
  }),

  setSearchTerm: (term, offset = 0) => ({
    type: Types.PRODUCT_SET_SEARCH_TERM,
    payload: { term, offset },
  }),

  setSearchIndustry: (industry, offset = 0) => ({
    type: Types.PRODUCT_SET_SEARCH_INDUTRY,
    payload: { industry, offset },
  }),

  setSearchProductType: (productType, offset = 0) => ({
    type: Types.PRODUCT_SET_SEARCH_PRODUCT_TYPE,
    payload: { productType, offset },
  }),

  setSearchCategoryGroup: (categoryGroup, offset = 0) => ({
    type: Types.PRODUCT_SET_SEARCH_CATEGORY_GROUP,
    payload: { categoryGroup, offset },
  }),

  setSearchCategory: (category, offset = 0) => ({
    type: Types.PRODUCT_SET_SEARCH_CATEGORY,
    payload: { category, offset },
  }),
};
