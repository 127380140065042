import styled from 'styled-components';

export const ModalKitButton = styled.button`
  background: none;
  color: #900;
  font-weight: normal;
  font-size: unset;
  border-radius: 0;
  text-decoration: underline;

  :hover {
    text-decoration: none;
  }
`;
