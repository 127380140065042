import { numberFormat } from '../../util/numberFormat';

export const Types = {
  SET_ITEMS_TO_SEND: 'order/SET_ITEMS_TO_SEND',

  GET_ORDER_REQUEST: 'order/GET_ORDER_REQUEST',
  GET_ORDER_SUCCESS: 'order/GET_ORDER_SUCCESS',
  GET_ORDER_FAILURE: 'order/GET_ORDER_FAILURE',

  CANCEL_ORDER_REQUEST: 'order/CANCEL_ORDER_REQUEST',
  CANCEL_ORDER_SUCCESS: 'order/CANCEL_ORDER_SUCCESS',
  CANCEL_ORDER_FAILURE: 'order/CANCEL_ORDER_FAILURE',

  CLOSE_ORDER_REQUEST: 'order/CLOSE_ORDER_REQUEST',
  CLOSE_ORDER_SUCCESS: 'order/CLOSE_ORDER_SUCCESS',
  CLOSE_ORDER_FAILURE: 'order/CLOSE_ORDER_FAILURE',

  CLOSE_ORDER_FAILURE_AND_SHOW_MESSAGE: 'order/CLOSE_ORDER_FAILURE_AND_SHOW_MESSAGE',

  SEND_ITEMS_REQUEST: 'order/SEND_ITEMS_REQUEST',
  SEND_ITEMS_SUCCESS: 'order/SEND_ITEMS_SUCCESS',
  SEND_ITEMS_FAILURE: 'order/SEND_ITEMS_FAILURE',
};

const INITIAL_STATE = {
  loading: false,
  error: null,
  sending: false,
  canceling: false,
  closing: false,
  data: {
    nr_pedido: '',
    clientInfo: { session: { bloqueio_pedido: { podeFechar: null }, cliente: { vlrMinimo: 50 } } },
    items: [],
  },
  itemError: [],
  itemsToSend: [],
  totals: {
    medicamentos: 0,
    perfumaria: 0,
    liquid: 0,
    total: 0,
  },
};

export default function order(state = INITIAL_STATE, action) {
  let newState = null;

  switch (action.type) {
    case Types.SET_ITEMS_TO_SEND:
      newState = { ...state, itemsToSend: action.payload.items };
      break;

    case Types.CANCEL_ORDER_REQUEST:
      newState = { ...state, loading: true, canceling: true };
      break;
    case Types.CANCEL_ORDER_SUCCESS:
      newState = {
        ...state,
        loading: false,
        canceling: false,
        error: null,
        data: {
          ...state.data,
          nr_pedido: '',
          items: [],
        },
        itemError: [],
      };
      break;
    case Types.CANCEL_ORDER_FAILURE:
      newState = {
        ...state,
        loading: false,
        canceling: false,
        error: action.payload.message,
      };
      break;

    case Types.CLOSE_ORDER_REQUEST:
      newState = { ...state, loading: true, closing: true };
      break;
    case Types.CLOSE_ORDER_SUCCESS:
      newState = {
        ...state,
        loading: false,
        closing: false,
        error: null,
        data: {
          ...state.data,
          nr_pedido: '',
          items: [],
        },
        itemError: [],
      };
      break;
    case Types.CLOSE_ORDER_FAILURE:
      newState = {
        ...state,
        loading: false,
        closing: false,
        error: action.payload.message,
        itemError: [],
      };
      break;
    case Types.CLOSE_ORDER_FAILURE_AND_SHOW_MESSAGE:
      newState = {
        ...state,
        loading: false,
        closing: false,
        itemError: action.payload.itemError,
      };
      break;

    case Types.GET_ORDER_REQUEST:
      newState = { ...state, itemError: [], loading: true };
      break;
    case Types.GET_ORDER_SUCCESS:
      newState = {
        ...state,
        loading: false,
        error: null,
        data: {
          ...state.data,
          nr_pedido: action.payload.data.nr_pedido,
          items: action.payload.data.items,
          clientInfo: {
            ...state.data.clientInfo,
            ...action.payload.data.clientInfo,
          },
        },
        itemError: [],
      };
      break;
    case Types.GET_ORDER_FAILURE:
      newState = {
        ...state,
        data: {
          ...state.data,
          nr_pedido: '',
          items: [],
        },
        loading: false,
        error: action.payload.message,
        itemError: [],
      };
      break;

    case Types.SEND_ITEMS_REQUEST:
      newState = {
        ...state, itemError: [], loading: true, sending: true,
      };
      break;
    case Types.SEND_ITEMS_SUCCESS:
      newState = {
        ...state,
        loading: false,
        sending: false,
        error: null,
        data: action.payload.data,
        itemsToSend: [],
        itemError: [],
      };
      break;
    case Types.SEND_ITEMS_FAILURE:
      newState = {
        ...state,
        data: {
          ...state.data,
          nr_pedido: '',
          items: [],
        },
        itemError: [],
        loading: false,
        sending: false,
        error: action.payload.message,
      };
      break;
    default:
      newState = state;
  }

  const total = newState.data.items.reduce(
    (acc, current) => acc + parseFloat(current.vlr_total) * current.qt_pedida,
    0,
  );

  const minimumReached = total >= parseFloat(newState.data.clientInfo.session.cliente.vlrMinimo);

  const medicamentosNumber = newState.data.items.reduce(
    (acc, current) => acc
      + (current.mercadoria.id_tipo_mercadoria === '1' && current.mercadoria.infos.id_falta === 'N'
        ? parseFloat(current.vlr_total) * current.qt_pedida
        : 0),
    0,
  );

  const perfumariaNumber = newState.data.items.reduce(
    (acc, current) => acc
      + (current.mercadoria.id_tipo_mercadoria === '2' && current.mercadoria.infos.id_falta === 'N'
        ? parseFloat(current.vlr_total) * current.qt_pedida
        : 0),
    0,
  );

  const kitNumber = newState.data.items.reduce(
    (acc, current) => acc
      + (current.mercadoria.id_tipo_mercadoria === '-1' && current.mercadoria.infos.id_falta === 'N'
        ? parseFloat(current.vlr_total) * current.qt_pedida
        : 0),
    0,
  );

  const liquidNumber = newState.data.items.reduce(
    (acc, current) => acc
      + (current.mercadoria.infos.id_falta === 'N'
        ? parseFloat(current.vlr_preco) * current.qt_pedida
        : 0),
    0,
  );

  return {
    ...newState,
    minimumReached,
    totals: {
      medicamentosNumber,
      medicamentos: numberFormat(medicamentosNumber),
      perfumariaNumber,
      perfumaria: numberFormat(perfumariaNumber),
      liquidNumber,
      liquid: numberFormat(liquidNumber),
      kitNumber,
      kit: numberFormat(kitNumber),
      totalNumber: total,
      total: numberFormat(total),
    },
  };
}

export const Creators = {
  setItemsToSend: items => ({
    type: Types.SET_ITEMS_TO_SEND,
    payload: { items },
  }),

  getOrderRequest: () => ({
    type: Types.GET_ORDER_REQUEST,
    payload: {},
  }),

  getOrderSuccess: data => ({
    type: Types.GET_ORDER_SUCCESS,
    payload: { data },
  }),

  getOrderFailure: message => ({
    type: Types.GET_ORDER_FAILURE,
    payload: { message },
  }),

  cancelOrderRequest: () => ({
    type: Types.CANCEL_ORDER_REQUEST,
    payload: {},
  }),

  cancelOrderSuccess: data => ({
    type: Types.CANCEL_ORDER_SUCCESS,
    payload: { data },
  }),

  cancelOrderFailure: message => ({
    type: Types.CANCEL_ORDER_FAILURE,
    payload: { message },
  }),

  closeOrderRequest: () => ({
    type: Types.CLOSE_ORDER_REQUEST,
    payload: {},
  }),

  closeOrderSuccess: data => ({
    type: Types.CLOSE_ORDER_SUCCESS,
    payload: { data },
  }),

  closeOrderFailure: message => ({
    type: Types.CLOSE_ORDER_FAILURE,
    payload: { message },
  }),

  closeOrderFailureAndShowMessage: itemError => ({
    type: Types.CLOSE_ORDER_FAILURE_AND_SHOW_MESSAGE,
    payload: { itemError },
  }),

  sendItemsRequest: (items, sum, callback) => ({
    type: Types.SEND_ITEMS_REQUEST,
    payload: { items, sum, callback },
  }),

  sendItemsSuccess: data => ({
    type: Types.SEND_ITEMS_SUCCESS,
    payload: { data },
  }),

  sendItemsFailure: message => ({
    type: Types.SEND_ITEMS_FAILURE,
    payload: { message },
  }),
};
