import React, { Fragment } from 'react';

// import { Container } from './styles';

const NoMatch = () => (
  <Fragment>
    <h1>Página não encontrada</h1>
  </Fragment>
);

export default NoMatch;
