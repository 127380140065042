import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Container, LogosContainer, NameContainer } from './styles';
import { baseURLAPI } from '../../services/api';
import { getClientData } from '../../services/auth';

import Clock from './components/Clock';

import logoEgam from '../../assets/images/logo-egam-internal.png';
import handshake from '../../assets/images/handshake-internal.png';

const Header = ({ client }) => {
  const clientData = client || getClientData();

  let projectId = false;
  let clientNameShow = false;
  if (clientData !== null) {
    const { cd_logged_project: project, ds_cliente: clientName } = clientData;
    projectId = project;
    clientNameShow = clientName;
  }

  return (
    <Container>
      <Link to="/">
        <LogosContainer>
          <img src={logoEgam} alt="eGAM" />
          <img className="handshakeInternal" src={handshake} alt="handshake" />
          {projectId && (
            <img src={`${baseURLAPI}static/projects/${projectId}/logo-internal.png`} alt="Parceiro" />
          )}
        </LogosContainer>
      </Link>

      <NameContainer>
        {clientNameShow && (
          <>
            Olá, {clientNameShow}!<br />
          </>
        )}
        Seja Bem-vindo!
      </NameContainer>

      <Clock />
    </Container>
  );
};

Header.defaultProps = {
  client: null,
};

Header.propTypes = {
  client: PropTypes.shape(),
};

const mapStateToProps = state => ({
  client: state.login.data,
});

export default connect(mapStateToProps)(Header);
