import { call, put, select } from 'redux-saga/effects';
import egam from '../../services/egam';
import { getClientData } from '../../services/auth';

import { Creators as ClockActions } from '../ducks/clock';

export function* clock() {
  try {
    const clientData = getClientData();
    const { cd_cliente: cliente } = clientData;

    const formData = new FormData();
    formData.append('cliente', cliente);

    const response = yield call(egam.post, 'relogio', formData);

    if (response.status !== 200) {
      yield put(
        ClockActions.clockTruckFailure('Não foi possível capturar configurações do relógio'),
      );
    } else {
      yield put(ClockActions.clockTruckSuccess(response.data));
    }
  } catch (err) {
    yield put(ClockActions.clockTruckFailure('Não foi possível capturar configurações do relógio'));
  }
}

export function* startClock() {
  let clockState = null;

  if (clockState === null) {
    yield call(clock);
    clockState = yield select(state => state.clock);
  }

  const dateClock = new Date(clockState.data.config.atual * 1000);
  let dateControl = new Date();

  while (true) {
    clockState = yield select(state => state.clock);
    yield new Promise(resolve => setTimeout(resolve, 1000));

    const actualDate = new Date();

    const diferenceBetweenActualControl = actualDate.getTime() - dateControl.getTime();

    dateClock.setTime(dateClock.getTime() + diferenceBetweenActualControl);

    dateControl = actualDate;

    const hours = `0${dateClock.getHours()}`;
    const minutes = `0${dateClock.getMinutes()}`;
    const seconds = `0${dateClock.getSeconds()}`;
    const formattedTime = `${hours.substr(-2)}:${minutes.substr(-2)}:${seconds.substr(-2)}`;

    // check if order sending hour is ok
    const closingTime = clockState.data.config.max.split(':');
    const closingLimit = new Date();
    closingLimit.setHours(closingTime[0]);
    closingLimit.setMinutes(closingTime[1]);
    closingLimit.setSeconds(0);

    const canSendOrders = actualDate < closingLimit;

    yield put(ClockActions.clockSetHour(formattedTime, canSendOrders));

    // update clock info each 5 minutes
    if (dateClock.getMinutes() % 5 === 0 && dateClock.getSeconds() === 0) {
      yield call(clock);
    }
  }
}
