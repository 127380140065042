import {
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as IndustriesActions } from '../../../store/ducks/industries';
import { Creators as ProductsActions } from '../../../store/ducks/products';
import { FilterSelect } from './FilterSelect';

const FilterIndustry = () => {
  const dispatch = useDispatch();
  const { data: industries, loaded } = useSelector(state => state.industries);
  const selected = useSelector(state => state.products.searchIndustry);

  useEffect(() => {
    if (!loaded) {
      dispatch(IndustriesActions.industryRequest());
    }
  }, [loaded, dispatch]);

  const onChange = (event) => {
    const value = event.target.value !== '0' ? event.target.value : '*';

    dispatch(ProductsActions.setSearchIndustry(value));
  };

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel>Laboratório:</InputLabel>
      <FilterSelect
        value={selected}
        onChange={onChange}
        label="Laboratório"
      >
        <MenuItem value="*">
          Todos os laboratórios
        </MenuItem>
        {
          industries.map(industry => (
            <MenuItem
              key={industry.cd_fornecedor}
              value={industry.cd_fornecedor}
            >
              {industry.ds_fornecedor}
            </MenuItem>
          ))
        }
      </FilterSelect>
    </FormControl>
  );
};

export default FilterIndustry;
