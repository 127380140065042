import styled from 'styled-components';

export const Container = styled.div`

`;

export const ValorInvalido = styled.div`
  font-weight: bold;
  color: #223f45;
`;

export const ParcelamentoContainer = styled.div`
  display: flex;
  label {
    font-size: 13px;
    font-weight: bold;
    margin-right: 15px;
  }
`;

export const ParcelamentoLabel = styled.label`
  span {
    display: block;
  }
  select {
    padding: 2px;
  }
`;

export const ParcelamentoTableHead = styled.thead`
  background-color: #7eb9cd;
  color: #223f45;
  th {
    padding: 3px;
  }
`;

export const ParcelamentoTableBody = styled.tbody`
  text-align: center;
  td {
    padding: 3px;
    border-bottom: 1px solid #ccc;
  }
`;
