import PropTypes from 'prop-types';
import React, { createContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as OrderActions } from '../../store/ducks/order';
import api from '../../services/api';

export const OrderImportContext = createContext({});

function OrderImportProvider({ children }) {
  const dispatch = useDispatch();
  const itemsOnOrder = useSelector(state => state.order.data.items);

  const [listItems, setListItems] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const sendItems = async (items) => {
    const itemsOnOrderIndexados = itemsOnOrder.reduce((acc, item) => {
      acc[item.mercadoria.full_cd_mercadoria] = item.qt_pedida;
      return acc;
    }, {});

    const itemsToSend = items
      .filter(item => item.status === 'SENDING')
      .map(item => ({
        mercadoria: item.code,
        qtd: parseInt(item.amount, 10),
        placeholder: parseInt(itemsOnOrderIndexados[item.code] ?? 0, 10) || 0,
      }));

    if (itemsToSend.length === 0) {
      return;
    }

    dispatch(OrderActions.sendItemsRequest(itemsToSend, true, () => {
      setListItems(state => state.map((item) => {
        if (item.status === 'SENDING') {
          return {
            ...item,
            status: 'SUCCESS',
          };
        }
        return item;
      }));

      setCurrentIndex(state => state + itemsToSend.length);
    }));
  };

  const startProcess = async (items) => {
    const itemsToProcess = items.map(item => ({
      ...item,
      name: '',
      status: 'WAITING',
    }));

    setListItems(itemsToProcess);

    await Promise.all(items.map(async (item) => {
      try {
        const { data } = await api.get(`/products/${item.ean}`);
        const {
          ds_apresentacao,
          full_cd_mercadoria,
          infos: {
            qt_fisica,
            qt_reservada,
          },
        } = data;

        const status = qt_fisica - qt_reservada >= item.amount ? 'SENDING' : 'OUT_OF_STOCK';

        itemsToProcess
          .filter(itemToProcess => itemToProcess.ean === item.ean)
          .forEach((itemToProcess) => {
            itemToProcess.name = ds_apresentacao;
            itemToProcess.code = full_cd_mercadoria;
            itemToProcess.status = status;
          });

        setListItems(itemsToProcess);
        if (status !== 'SENDING') {
          setCurrentIndex(state => state + 1);
        }
      } catch (error) {
        itemsToProcess
          .filter(itemToProcess => itemToProcess.ean === item.ean)
          .forEach((itemToProcess) => {
            itemToProcess.name = '';
            itemToProcess.status = 'NOT_FOUND';
          });
        setListItems(itemsToProcess);
        setCurrentIndex(state => state + 1);
      }
    }));

    sendItems(itemsToProcess);
  };

  return (
    <OrderImportContext.Provider
      value={{
        listItems,
        currentIndex,
        startProcess,
      }}
    >
      {children}
    </OrderImportContext.Provider>
  );
}

OrderImportProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrderImportProvider;
