export const Types = {
  SET_PARCELAS_REQUEST: "parcelamento/SET_PARCELAS_REQUEST",
  SET_INTERVALOS_REQUEST: "parcelamento/SET_INTERVALOS_REQUEST",
  SET_PRAZO_MEDIO_REQUEST: "parcelamento/SET_PRAZO_MEDIO_REQUEST",

  GET_PARCELAMENTO_REQUEST: "parcelamento/GET_PARCELAMENTO_REQUEST",
  GET_PARCELAMENTO_SUCCESS: "parcelamento/GET_PARCELAMENTO_SUCCESS",
  GET_PARCELAMENTO_FAILURE: "parcelamento/GET_PARCELAMENTO_FAILURE",

  GET_INTERVALO_REQUEST: "parcelamento/GET_INTERVALO_REQUEST",
  GET_INTERVALO_SUCCESS: "parcelamento/GET_INTERVALO_SUCCESS",

  GET_PRAZO_MEDIO_REQUEST: "parcelamento/GET_PRAZO_MEDIO_REQUEST",
  GET_PRAZO_MEDIO_SUCCESS: "parcelamento/GET_PRAZO_MEDIO_SUCCESS",

  GET_CONDICOES_REQUEST: "parcelamento/GET_CONDICOES_REQUEST",
  GET_CONDICOES_SUCCESS: "parcelamento/GET_CONDICOES_SUCCESS",

  GET_PARCELAMENTO_GENERICO: "parcelamento/GET_PARCELAMENTO_GENERICO",
  SET_PARCELAMENTO_GENERICO: "parcelamento/SET_PARCELAMENTO_GENERICO",

  GET_PARCELAMENTO_ESPECIAL: "parcelamento/GET_PARCELAMENTO_ESPECIAL",
  SET_PARCELAMENTO_ESPECIAL: "parcelamento/SET_PARCELAMENTO_ESPECIAL",

  GET_PARCELAMENTO_SOLAR: "parcelamento/GET_PARCELAMENTO_SOLAR",
  SET_PARCELAMENTO_SOLAR: "parcelamento/SET_PARCELAMENTO_SOLAR",

  GET_PARCELAMENTO_NORMAL: "parcelamento/GET_PARCELAMENTO_NORMAL",

  SET_PARCELA_GENERICO_CALCULADO: "parcelamento/SET_PARCELA_GENERICO_CALCULADO",

  CALCULATE_MINIMUM: "parcelamento/CALCULATE_MINIMUM",
  SET_MINIMUM: "parcelamento/SET_MINIMUM",
  RESET_PARCELAMENTO: "parcelamento/RESET_PARCELAMENTO",
};

const INITIAL_STATE = {
  mensagemErro: "",
  parcelas: [],
  parcela: "",
  intervalos: [],
  intervalo: "",
  prazoMedio: [],
  prazo: "",
  condicoesParcelamento: [],
  codigoParcelamento: "",
  parcelamentoGenericos: false,
  qtdDias: "",
  minimum: 0,
};

export default function order(state = INITIAL_STATE, action) {
  let newState = null;

  switch (action.type) {
    case Types.RESET_PARCELAMENTO:
      newState = {
        ...state,
        mensagemErro: "",
        parcelas: [],
        parcela: "",
        intervalos: [],
        intervalo: "",
        prazoMedio: [],
        prazo: "",
        condicoesParcelamento: [],
        codigoParcelamento: "",
        qtdDias: "",
        parcelamentoGenericos: false,
        parcelamentoEspecial: false,
        minimum: 0,
      };
      break;
    case Types.GET_PARCELAMENTO_REQUEST:
      newState = {
        ...state,
        parcelas: [],
        parcela: "",
        intervalos: [],
        intervalo: "",
        prazoMedio: [],
        prazo: "",
        condicoesParcelamento: [],
        qtdDias: "",
      };
      break;
    case Types.GET_PARCELAMENTO_NORMAL:
      newState = {
        ...state,
        mensagemErro: "",
        parcelas: [],
        parcela: "",
        intervalos: [],
        intervalo: "",
        prazoMedio: [],
        prazo: "",
        condicoesParcelamento: [],
        codigoParcelamento: "",
        qtdDias: "",
      };
      break;
    case Types.GET_INTERVALO_REQUEST:
      newState = {
        ...state,
        intervalos: [],
        intervalo: "",
        prazoMedio: [],
        prazo: "",
        condicoesParcelamento: [],
        parcelamentoGenericos: false,
        parcelamentoEspecial: false,
        qtdDias: "",
      };
      break;
    case Types.GET_PRAZO_MEDIO_REQUEST:
      newState = {
        ...state,
        prazoMedio: [],
        prazo: "",
        condicoesParcelamento: [],
        parcelamentoGenericos: false,
        parcelamentoEspecial: false,
        qtdDias: "",
      };
      break;
    case Types.GET_CONDICOES_REQUEST:
      newState = { ...state, condicoesParcelamento: [] };
      break;
    case Types.GET_PARCELAMENTO_SUCCESS:
      newState = {
        ...state,
        parcelas: action.payload.data.parcelas || [],
        parcela: "",
        intervalos: [],
        intervalo: "",
        prazoMedio: [],
        prazo: "",
        condicoesParcelamento: [],
        codigoParcelamento: "",
        qtdDias: "",
      };
      break;
    case Types.GET_INTERVALO_SUCCESS:
      newState = {
        ...state,
        intervalos: action.payload.data.intervalos || [],
        prazoMedio: [],
        condicoesParcelamento: [],
        parcelamentoGenericos: false,
        parcelamentoEspecial: false,
        qtdDias: "",
      };
      break;
    case Types.GET_PRAZO_MEDIO_SUCCESS:
      newState = {
        ...state,
        prazoMedio: action.payload.data.prazoMedio || [],
        prazo: "",
        prazoMaximo: action.payload.data.callback.post.PZ_MAXIMO || "",
        condicoesParcelamento: [],
        parcelamentoGenericos: false,
        parcelamentoEspecial: false,
        qtdDias: "",
      };
      break;
    case Types.GET_CONDICOES_SUCCESS:
      newState = {
        ...state,
        condicoesParcelamento:
          action.payload.data.condicoesParcelamento ||
          state.condicoesParcelamento,
        codigoParcelamento: action.payload.data.callback.post.cd_parcelamento,
      };
      break;
    case Types.GET_PARCELAMENTO_FAILURE:
      newState = {
        ...state,
        mensagemErro: action.payload.message,
      };
      break;
    case Types.SET_PARCELAS_REQUEST:
      newState = {
        ...state,
        parcela: action.payload.parcela,
        intervalos: [],
        intervalo: "",
        prazoMedio: [],
        prazo: "",
        condicoesParcelamento: [],
        codigoParcelamento: "",
      };
      break;
    case Types.SET_INTERVALOS_REQUEST:
      newState = {
        ...state,
        intervalo: action.payload.intervalo,
        prazoMedio: [],
        prazo: "",
        condicoesParcelamento: [],
        codigoParcelamento: "",
      };
      break;
    case Types.SET_PRAZO_MEDIO_REQUEST:
      newState = {
        ...state,
        prazo: action.payload.prazo,
        condicoesParcelamento: [],
        codigoParcelamento: "",
      };
      break;
    case Types.GET_PARCELAMENTO_GENERICO:
      newState = {
        ...state,
        parcelamentoGenericos: true,
        parcelamentoEspecial: false,
        condicoesParcelamento: "",
        qtdDias: "",
      };
      break;
    case Types.SET_PARCELAMENTO_GENERICO:
      newState = {
        ...state,
        parcelamentoGenericos: true,
        parcelamentoEspecial: false,
        codigoParcelamento: action.payload.codigoParcelamento,
        qtdDias: action.payload.qtdDias,
      };
      break;
    case Types.GET_PARCELAMENTO_ESPECIAL:
      newState = {
        ...state,
        parcelamentoGenericos: false,
        parcelamentoEspecial: true,
        condicoesParcelamento: "",
        qtdDias: "",
      };
      break;
    case Types.SET_PARCELAMENTO_ESPECIAL:
      newState = {
        ...state,
        parcelamentoGenericos: false,
        parcelamentoEspecial: true,
        codigoParcelamento: action.payload.codigoParcelamento,
        qtdDias: action.payload.qtdDias,
      };
      break;
    case Types.GET_PARCELAMENTO_SOLAR:
      newState = {
        ...state,
        parcelamentoGenericos: false,
        parcelamentoEspecial: false,
        condicoesParcelamento: "",
        qtdDias: "",
      };
      break;
    case Types.SET_PARCELAMENTO_SOLAR:
      newState = {
        ...state,
        parcelamentoGenericos: false,
        parcelamentoEspecial: false,
        codigoParcelamento: action.payload.codigoParcelamento,
        qtdDias: action.payload.qtdDias,
      };
      break;
    case Types.SET_PARCELA_GENERICO_CALCULADO:
      newState = {
        ...state,
        condicoesParcelamento: action.payload.condicoesParcelamento,
      };
      break;
    case Types.CALCULATE_MINIMUM:
      newState = {
        ...state,
        minimum: 0,
      };
      break;
    case Types.SET_MINIMUM:
      newState = {
        ...state,
        minimum: action.payload.minimum,
      };
      break;
    default:
      newState = state;
  }

  return {
    ...newState,
  };
}

export const Creators = {
  resetParcelamento: () => ({
    type: Types.RESET_PARCELAMENTO,
    payload: {},
  }),

  setParcelas: (parcela) => ({
    type: Types.SET_PARCELAS_REQUEST,
    payload: { parcela },
  }),

  setIntervalos: (intervalo) => ({
    type: Types.SET_INTERVALOS_REQUEST,
    payload: { intervalo },
  }),

  setPrazoMedio: (prazo) => ({
    type: Types.SET_PRAZO_MEDIO_REQUEST,
    payload: { prazo },
  }),

  getParcelamentoRequest: () => ({
    type: Types.GET_PARCELAMENTO_REQUEST,
    payload: {},
  }),

  getIntervaloRequest: () => ({
    type: Types.GET_INTERVALO_REQUEST,
    payload: {},
  }),

  getPrazoMedioRequest: () => ({
    type: Types.GET_PRAZO_MEDIO_REQUEST,
    payload: {},
  }),

  getCondicoesRequest: () => ({
    type: Types.GET_CONDICOES_REQUEST,
    payload: {},
  }),

  getPercelamentoSuccess: (data) => ({
    type: Types.GET_PARCELAMENTO_SUCCESS,
    payload: { data },
  }),

  getIntervaloSuccess: (data) => ({
    type: Types.GET_INTERVALO_SUCCESS,
    payload: { data },
  }),

  getPrazoMedioSuccess: (data) => ({
    type: Types.GET_PRAZO_MEDIO_SUCCESS,
    payload: { data },
  }),

  getCondicoesSuccess: (data) => ({
    type: Types.GET_CONDICOES_SUCCESS,
    payload: { data },
  }),

  getPercelamentoFailure: (message) => ({
    type: Types.GET_PARCELAMENTO_FAILURE,
    payload: { message },
  }),

  setParcelamentoGenerico: (qtdDias, codigoParcelamento) => ({
    type: Types.SET_PARCELAMENTO_GENERICO,
    payload: { qtdDias, codigoParcelamento },
  }),

  getPercelamentoGenerico: () => ({
    type: Types.GET_PARCELAMENTO_GENERICO,
  }),

  setParcelamentoEspecial: (qtdDias, codigoParcelamento) => ({
    type: Types.SET_PARCELAMENTO_ESPECIAL,
    payload: { qtdDias, codigoParcelamento },
  }),

  getPercelamentoEspecial: () => ({
    type: Types.GET_PARCELAMENTO_ESPECIAL,
  }),

  getPercelamentoSolar: () => ({
    type: Types.GET_PARCELAMENTO_SOLAR,
  }),

  setParcelamentoSolar: (qtdDias, codigoParcelamento) => ({
    type: Types.SET_PARCELAMENTO_SOLAR,
    payload: { qtdDias, codigoParcelamento },
  }),

  getParcelamentoNormal: () => ({
    type: Types.GET_PARCELAMENTO_NORMAL,
  }),

  calculateParcelamentoGenerico: (condicoesParcelamento) => ({
    type: Types.SET_PARCELA_GENERICO_CALCULADO,
    payload: { condicoesParcelamento },
  }),

  calculateMinimum: () => ({
    type: Types.CALCULATE_MINIMUM,
  }),
  setMinimum: (minimum) => ({
    type: Types.SET_MINIMUM,
    payload: { minimum },
  }),
};
