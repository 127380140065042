import {
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as CategoriesActions } from '../../../store/ducks/categories';
import { Creators as ProductsActions } from '../../../store/ducks/products';
import { FilterSelect } from './FilterSelect';

const FilterCategoryGroup = () => {
  const dispatch = useDispatch();
  const { data: categories, loaded } = useSelector(state => state.categories);
  const productType = useSelector(state => state.products.searchProductType);
  const selected = useSelector(state => state.products.searchCategoryGroup);

  const categoriesGroup = Object.keys(categories
    .filter(category => category.cod_type === productType)
    .reduce((acc, category) => {
      if (!acc[category.group]) {
        acc[category.group] = [];
      }

      acc[category.group].push(category.category);

      return acc;
    }, {}));

  useEffect(() => {
    if (!loaded) {
      dispatch(CategoriesActions.categoryRequest());
    }
  }, [loaded, dispatch]);

  const onChange = (event) => {
    const value = event.target.value !== '0' ? event.target.value : '*';

    dispatch(ProductsActions.setSearchCategoryGroup(value));
    dispatch(ProductsActions.setSearchCategory('*'));
  };

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel>Grupo Categoria:</InputLabel>
      <FilterSelect
        value={selected}
        onChange={onChange}
        label="Grupo Categoria"
      >
        <MenuItem value="*">
          Todos os níveis
        </MenuItem>
        {
          categoriesGroup.map(category => (
            <MenuItem
              key={category}
              value={category}
              selected={selected === category}
            >
              {category}
            </MenuItem>
          ))
        }
      </FilterSelect>
    </FormControl>
  );
};

export default FilterCategoryGroup;
