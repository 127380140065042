import styled from "styled-components";

export const BloqueioInfoLink = styled.span`
  color: rgb(0, 0, 238);
  cursor: pointer;
  text-decoration: underline;
`;

export const BloqueioDescriptionText = styled.p`
  margin-bottom: 25px;

  :last-child {
    margin-bottom: 50px;
  }
`;
