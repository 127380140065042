import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import InputFile from './InputFile';
import ImporterProgress from './ImporterProgress';
import ItemsTable from './ItemsTable';
import { OrderImportContext } from './OrderImportContext';

function FileImporter() {
  const { listItems } = useContext(OrderImportContext);

  if (listItems.length === 0) {
    return <InputFile />;
  }

  return (
    <Box display="flex" flexDirection="column" style={{ gap: '0.75rem' }}>
      <ItemsTable />
      <ImporterProgress />
    </Box>
  );
}

export default FileImporter;
