import styled from 'styled-components';

import metrics from '../../styles/metrics';

import bg from '../../assets/images/search-bg.png';

export const Container = styled.div`
  display: flex;
  background: transparent url(${bg}) left top;
  margin-bottom: ${metrics.baseMargin}px;
  padding: ${metrics.baseMargin}px;
  justify-content: center;
  width: 100%;
`;

export const SolarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${metrics.baseMargin}px;
  justify-content: center;

  p {
    text-align: center;
    margin-bottom: ${metrics.baseMargin}px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 10px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 400px;
  padding: 5px;
  position: relative;
  justify-content: space-between;
  margin-top: 15px;
`;

export const Filter = styled.div`
  width: 100%;
  background-color: #83c1d6;
  flex-direction: column;
  padding: 10px;
  box-shadow: rgba(50, 50, 50, 0.75) 3px 5px 5px;
  position: absolute;
  top: 49px;
  display: ${props => (props.showingFilters ? 'flex' : 'none')};

  select {
    padding: 5px;
    font-size: 1em;
  }
`;

export const BtnFilter = styled.button.attrs(() => ({
  type: 'button',
}))`
  display: inline-flex;
  order: 1;
  color: #555;
  width: 200px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: ${props => (props.active ? '#83c1d6' : 'none')};
`;

export const BtnSolar = styled.button.attrs(() => ({
  type: 'submit',
}))`
  display: flex;
  height: 40px;
  order: 2;
  align-self: center;
`;

export const FilterText = styled.span`
  margin: 5px 0 0 5px;
`;

export const FieldContainer = styled.form`
  background: #fff;
  width: 100%;
  display: flex;
  padding: 3px;
  border: 1px solid #8e9a99;
  border-radius: 10px;

  input {
    flex: 1;
    padding: 5px;
    font-size: 2em;
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
  }
`;
