import React from 'react';
import PropTypes from 'prop-types';

import { ParcelamentoContainer, ParcelamentoLabel } from '../style';
import FixedCondition from './FixedCondition';

function FixedParcelamento(props) {
  const {
    qtdDias, changeParcelamento, condicoes,
  } = props;

  return (
    <ParcelamentoContainer>
      <ParcelamentoLabel>
        <span>Parcelas:</span>
        <FixedCondition
          qtdDias={qtdDias}
          changeParcelamento={changeParcelamento}
          condicoes={condicoes}
        />
      </ParcelamentoLabel>
    </ParcelamentoContainer>
  );
}

FixedParcelamento.propTypes = {
  qtdDias: PropTypes.string.isRequired,
  changeParcelamento: PropTypes.func.isRequired,
  condicoes: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    cdParcela: PropTypes.number.isRequired,
  })).isRequired,
};

export default FixedParcelamento;
