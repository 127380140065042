import { combineReducers } from 'redux';
import login from './login';
import order from './order';
import products from './products';
import clock from './clock';
import industries from './industries';
import categories from './categories';
import parcelamento from './parcelamento';
import kit from './kit';

export default combineReducers({
  login,
  order,
  products,
  clock,
  industries,
  categories,
  parcelamento,
  kit,
});
