import { Box, Button, Grid } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import logo from '../../assets/images/logo.png';
import { campanhaSolar, isCampanhaSolar } from '../../services/auth';
import { Creators as IndustriesActions } from '../../store/ducks/industries';
import { Creators as OrderActions } from '../../store/ducks/order';
import { Creators as ProductsActions } from '../../store/ducks/products';
import FilterCategory from './components/FilterCategory';
import FilterCategoryGroup from './components/FilterCategoryGroup';
import FilterIndustry from './components/FilterIndustry';
import FilterProductType from './components/FilterProductType';
import SearchText from './components/SearchText';
import {
  BtnSolar,
  Container,
  Form,
  SolarContainer,
} from './styles';

class Search extends Component {
  state = {
    isCampanhaSolar: isCampanhaSolar.get(),
  };

  static propTypes = {
    setSearchTerm: PropTypes.func.isRequired,
    setSearchIndustry: PropTypes.func.isRequired,
    setSearchCategory: PropTypes.func.isRequired,
    setSearchCategoryGroup: PropTypes.func.isRequired,
    setSearchProductType: PropTypes.func.isRequired,
    getOrderRequest: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired,
    searchIndustry: PropTypes.string.isRequired,
    searchCategory: PropTypes.string.isRequired,
    searchCategoryGroup: PropTypes.string.isRequired,
    searchProductType: PropTypes.string.isRequired,
    history: PropTypes.shape().isRequired,
  };

  clearSearchText = () => {
    const {
      history,
      setSearchTerm,
      setSearchIndustry,
      setSearchCategory,
      setSearchCategoryGroup,
      setSearchProductType,
    } = this.props;
    const newPath = history.location.pathname === '/pedido' ? history.location.pathname : '/';
    setSearchTerm('*');
    setSearchIndustry('*');
    setSearchCategory('*');
    setSearchCategoryGroup('*');
    setSearchProductType('*');
    history.push(newPath);
  };

  solarCampain = async (event) => {
    const { isCampanhaSolar: isCampanhaSolarState } = this.state;
    const { getOrderRequest } = this.props;
    if ((isCampanhaSolarState.get && isCampanhaSolarState.get()) || isCampanhaSolarState) {
      await this.leaveCampain(event);
    } else {
      await this.enterCampain(event);
    }
    this.clearSearchText();
    getOrderRequest();
  };

  enterCampain = async (event) => {
    // Entrar na Campanha Solar
    if (window.confirm('Deseja entrar na campanha solar ?')) {
      await campanhaSolar(true);
      await this.setState({ isCampanhaSolar: true });
      this.handleSubmit(event);
    }
  };

  leaveCampain = async (event) => {
    // Sair da Campanha Solar
    if (window.confirm('Deseja sair da campanha solar ?')) {
      await campanhaSolar(false);
      await this.setState({ isCampanhaSolar: false });
      this.clearSearchText();
      this.handleSubmit(event);
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const {
      history,
      searchTerm,
      searchIndustry,
      searchCategory,
      searchCategoryGroup,
      searchProductType,
    } = this.props;
    if (
      searchTerm !== ''
      || searchIndustry !== '0'
      || searchCategory !== '0'
      || searchCategoryGroup !== '0'
      || searchProductType !== '0'
    ) {
      history.push(`/produtos/buscar/${searchTerm}/${searchIndustry}/${searchProductType}/${searchCategoryGroup}/${searchCategory}`);
    }
  };

  render() {
    const { isCampanhaSolar: isCampanhaSolarState } = this.state;

    return (
      <Container>
        <Box mr={3} display="flex" alignItems="center">
          <img src={logo} alt="eGAM" width={250} />
        </Box>

        <Form onSubmit={this.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FilterProductType />
            </Grid>

            <Grid item xs={6}>
              <FilterCategoryGroup />
            </Grid>

            <Grid item xs={6}>
              <FilterCategory />
            </Grid>

            <Grid item xs={6}>
              <FilterIndustry />
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Box flexGrow="1" mr={2}>
                  <SearchText />
                </Box>

                <Box mr={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SearchIcon />}
                    type="submit"
                  >
                    Filtrar
                  </Button>
                </Box>

                <Box>
                  <Button
                    variant="contained"
                    startIcon={<ClearIcon />}
                    onClick={this.clearSearchText}
                  >
                    Limpar
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Form>

        <SolarContainer>
          { isCampanhaSolarState && (
            <p>
              Você está vendo produtos somente da <strong>Campanha Solar</strong>.
            </p>
          )}
          <BtnSolar onClick={this.solarCampain}>
            { isCampanhaSolarState === false
              ? 'Entrar na Campanha Solar'
              : 'Sair da campanha Solar'}
          </BtnSolar>
        </SolarContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  searchTerm: state.products.searchTerm,
  searchIndustry: state.products.searchIndustry,
  searchCategory: state.products.searchCategory,
  searchCategoryGroup: state.products.searchCategoryGroup,
  searchProductType: state.products.searchProductType,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...ProductsActions,
    ...IndustriesActions,
    ...OrderActions,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Search);
