import styled from 'styled-components';

import metrics from '../../styles/metrics';

import orderIcon from '../../assets/images/order-icon.png';

export const Container = styled.div`
  height: ${metrics.footerHeight}px;
  width: 100%;
  background: #87d1e1;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: row;

  div {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 10px 10px 54px;

    p {
      position: relative;
    }

    span {
      margin-left: 5px;
    }
  }
`;

export const OrderContainer = styled.div`
  background: #83c1d6 url(${orderIcon}) no-repeat 10px center;

  div {
    align-items: left;
    padding-left: 0;
  }

  span {
    color: #666;
    margin-right: 10px;
  }

  a {
    color: #000;
    text-decoration: none;
  }
`;

export const IconProductType = styled.div`
  p {
    padding-left: 15px;
  }

  p:before {
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -5px;
    width: 10px;
    height: 10px;
    content: '';
    background: ${props => props.color};
    border-radius: 50%;
  }
`;

export const LiquidContainer = styled.div`
  background: #83c1d6;
`;

export const GrossContainer = styled.div`
  background: #7eb9cd;
`;
