import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Loader from 'react-loader-spinner';

import { Creators as LoginActions } from '../../store/ducks/login';

import { setTitle } from '../../services/browser';

import logoEgam from '../../assets/images/logo-egam-login.png';

import { Container, LogosContainer, Content } from './styles';

class Login extends Component {
  static propTypes = {
    loginRequest: PropTypes.func.isRequired,
    history: PropTypes.shape().isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        token: PropTypes.string.isRequired,
        project: PropTypes.string.isRequired,
        coreIndustry: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
  };

  componentDidMount() {
    const {
      match: {
        params: { token, coreIndustry },
      },
      loginRequest,
      loggedIn,
    } = this.props;

    if (!loggedIn && token) {
      loginRequest(token, coreIndustry);
    }
  }

  componentDidUpdate() {
    const { history, loading } = this.props;

    if (!loading) {
      history.push('/');
    }
  }

  render() {
    setTitle('Autenticando...');

    const {
      loading
    } = this.props;
    return (
      <Container>
        <Content>
          <LogosContainer>
            <img src={logoEgam} alt="eGAM" />
          </LogosContainer>
          {!loading && <Loader type="ThreeDots" color="#7EB9CD" height={100} width={100} />}
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.login.loggedIn,
  loading: state.login.loading,
});

const mapDispatchToProps = dispatch => bindActionCreators(LoginActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
