import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import React, { useContext, useRef, useState } from 'react';
import { OrderImportContext } from './OrderImportContext';

export default function InputFile() {
  const { startProcess } = useContext(OrderImportContext);

  const inputFileRef = useRef(null);

  const [filesList, setFilesList] = useState([]);
  const [loading, setLoading] = useState(false);

  const parseFilesContent = () => {
    setLoading(true);
    const items = filesList.reduce((acc, file) => {
      const lines = file.content.split('\n');
      lines
        .map(line => line.trim())
        .filter(line => line !== '')
        .forEach((line) => {
          const [ean, amount] = line.split(';');
          if (!acc[ean]) {
            acc[ean] = { ean, amount: 0 };
          }
          acc[ean].amount += parseInt(amount, 10);
        });
      return acc;
    }, {});
    startProcess(Object.values(items));
    setFilesList([]);
    setLoading(false);
  };

  const downloadTemplate = (event) => {
    event.preventDefault();

    const content = 'código de barras;quantidade (Excluir esta linha)\r\n1111111111111;2\r\n2222222222222;5\r\n3333333333333;3\r\n';

    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`);
    element.setAttribute('download', 'exemplo_importar.txt');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const handleFileUpload = async (event) => {
    const { files } = event.target;
    if (!files || files.length === 0) {
      return;
    }

    const filesArray = Array.from(files);
    const readFilePromises = filesArray.map(
      file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target?.result;
          resolve({ name: file.name, content, size: file.size });
        };
        reader.onerror = () => reject();
        reader.readAsText(file);
      }),
    );

    const filesContent = await Promise.all(readFilePromises);
    setFilesList(state => [...state, ...filesContent]);

    inputFileRef.current.value = '';
  };

  const handleDeleteFile = (index) => {
    setFilesList(state => state.filter((_, i) => i !== index));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      maxWidth="600px"
      style={{
        gap: '0.75rem',
      }}
    >
      <p>Envie um arquivo .txt com os EANs e quantidades dos produtos.</p>

      <Box>
        <a href="/" onClick={downloadTemplate}>Clique aqui para baixar um arquivo modelo</a>
      </Box>

      <label htmlFor="input-file">
        <input
          ref={inputFileRef}
          id="input-file"
          multiple
          type="file"
          style={{
            display: 'none',
          }}
          accept=".txt"
          disabled={loading}
          onChange={event => handleFileUpload(event)}
        />

        <Button
          variant="outlined"
          color="primary"
          component="span"
          disabled={loading}
          style={{
            textTransform: 'none',
            padding: '2rem 1rem',
          }}
          fullWidth
        >
          Selecionar arquivo
        </Button>
      </label>

      {filesList.map((file, index) => (
        <Box key={file.name} display="flex" justifyContent="space-between">
          <Box
            key={file.name}
            display="flex"
            alignItems="center"
            style={{
              gap: '0.75rem',
            }}
          >
            <DescriptionOutlinedIcon
              style={{
                color: 'gray',
                width: '2rem',
                height: '2rem',
              }}
            />
            <Box>
              <Typography variant="body1" color="textSecondary">
                <strong>{file.name}</strong>
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {file.size} bytes
              </Typography>
            </Box>
          </Box>

          <Tooltip title="Deletar arquivo">
            <IconButton
              onClick={() => handleDeleteFile(index)}
              disabled={loading}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ))}

      <Box>
        <Button
          style={{ textTransform: 'none' }}
          color="primary"
          variant="contained"
          onClick={parseFilesContent}
          disabled={filesList.length === 0 || loading}
        >
          Enviar arquivo
        </Button>
      </Box>
    </Box>
  );
}
