import styled from 'styled-components';

export const ModalTable = styled.table`
  border: 1px solid #ccc;
  padding: 3px;
  width: 100%;

  th {
    background: #7eb9cd;
    padding: 5px;
    color: #223f45;
  }
`;

export const ProductNameColumn = styled.td`
  text-align: start;
`;

export const KitProductLine = styled.tr`
  td {
    padding: 5px;
  }
`;
