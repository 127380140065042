import React from "react";
import PropTypes from "prop-types";

import {
  ProductsTable, ItemLine, CodColumn, QtdColumn, NoQtdColumn, VlrColumn,
} from '../../styles/common';
import ProductName from '../ProductName';
import ProductInfo from '../ProductInfo';
import { DelColumn, TotalsRow, LabelColumn } from './styles';

const OrderItemsList = ({
  loading,
  items,
  perfumaria,
  medicamentos,
  liquid,
  total,
  itemsToSend,
  minimumReached,
  setQt,
  delItem,
}) => (
  <ProductsTable>
    <thead>
      <tr>
        <th>Cód.</th>
        <th>Inf.</th>
        <th>Mercadoria</th>
        <th>Qtd</th>
        <th>Preço líq.</th>
        <th>Imposto</th>
        <th>Preço final</th>
        <th>Excluir</th>
      </tr>
    </thead>
    <tbody>
      {!loading &&
        items.map((item) => {
          let qty = item.qt_pedida;

          for (let i = 0; i < itemsToSend.length; i += 1) {
            if (item.cd_mercadoria === itemsToSend[i].cd_mercadoria) {
              qty = itemsToSend[i].qtd;
              break;
            }
          }

          let qtDisponivel = null;
          let maxQtd = null;

          if (item.mercadoria.bloqueio) {
            qtDisponivel = (item.mercadoria.bloqueio) ? item.mercadoria.bloqueio.qt_disponivel : null;
            maxQtd = ((qtDisponivel) ? qtDisponivel : 0) - qty;
          }
          return (
            <ItemLine
              key={item.cd_mercadoria}
              bloqueio={item.mercadoria.bloqueio}
            >
              <CodColumn>{`${item.mercadoria.full_cd_mercadoria}`}</CodColumn>
              <td>
                <ProductInfo product={item.mercadoria} />
              </td>
              <td>
                <ProductName product={item.mercadoria} />
              </td>
              {item.mercadoria.infos.id_falta === "N" ? (
                <QtdColumn
                  estoqueBaixo={
                    item.mercadoria.infos.qt_fisica -
                      item.mercadoria.infos.qt_reservada <=
                    15
                  }
                >
                  <input
                    onChange={(event) => setQt(item, event.target.value)}
                    type="number"
                    value={qty}
                    max={maxQtd}
                    min={0}
                  />
                </QtdColumn>
              ) : (
                <NoQtdColumn>---</NoQtdColumn>
              )}

              <VlrColumn>{`${item.mercadoria.infos.vl_preco_liq_formated}`}</VlrColumn>
              <VlrColumn>{`${item.mercadoria.infos.vl_imposto_formated}`}</VlrColumn>
              <VlrColumn>{`${item.mercadoria.infos.vl_total_formated}`}</VlrColumn>
              <DelColumn>
                <input
                  onChange={(event) => delItem(item, event)}
                  type="checkbox"
                />
              </DelColumn>
            </ItemLine>
          );
        })}
    </tbody>
    <tfoot>
      <TotalsRow>
        <LabelColumn>Total de perfumaria:</LabelColumn>
        <VlrColumn>{perfumaria}</VlrColumn>
      </TotalsRow>
      <TotalsRow>
        <LabelColumn>Total de medicamentos:</LabelColumn>
        <VlrColumn>{medicamentos}</VlrColumn>
      </TotalsRow>
      <TotalsRow className={!minimumReached && "minimumNotReached"}>
        <LabelColumn>Total líquido:</LabelColumn>
        <VlrColumn>{liquid}</VlrColumn>
      </TotalsRow>
      <TotalsRow>
        <LabelColumn>Total com impostos:</LabelColumn>
        <VlrColumn>{total}</VlrColumn>
      </TotalsRow>
    </tfoot>
  </ProductsTable>
);

OrderItemsList.propTypes = {
  loading: PropTypes.bool.isRequired,
  perfumaria: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  medicamentos: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  liquid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  minimumReached: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  itemsToSend: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  delItem: PropTypes.func.isRequired,
  setQt: PropTypes.func.isRequired,
};

export default OrderItemsList;
