import {
  Box, LinearProgress, Typography,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { OrderImportContext } from './OrderImportContext';

export default function ImporterProgress() {
  const { currentIndex, listItems } = useContext(OrderImportContext);

  const hasError = listItems.some(item => item.status !== 'SUCCESS');

  const progress = (currentIndex / listItems.length) * 100;

  if (currentIndex > -1 && currentIndex < listItems.length) {
    return (
      <Box>
        <Typography>Andamento da importação do seu arquivo:</Typography>
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress variant="determinate" value={progress} />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">
              {currentIndex}/{listItems.length}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{
        textAlign: 'center',
        gap: '0.75rem',
      }}
    >
      <Typography variant="subtitle2">
        {hasError
          ? 'Arquivo processado, mas algumas mercadorias não foram adicionadas ao pedido. Confira acima o retorno de cada item.'
          : 'Arquivo processado com sucesso! Confira acima o retorno de cada item.'}
      </Typography>

      <Typography>
        <Link to="/pedido">Clique aqui</Link> para acessar o seu pedido.
      </Typography>
    </Box>
  );
}
