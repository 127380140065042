import React from 'react';

import Loader from 'react-loader-spinner';

import { Container } from './styles';

const LoadingResults = () => (
  <Container>
    <td colSpan="6">
      <Loader type="ThreeDots" color="#ccc" height={50} width={50} />
    </td>
  </Container>
);

export default LoadingResults;
