import React, { Component } from "react";
import PropTypes from "prop-types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Loader from "react-loader-spinner";

import { Creators as OrderActions } from "../../store/ducks/order";

import { setTitle } from "../../services/browser";

import { isCampanhaSolar } from "../../services/auth";

import { ButtonsContainer, LoaderContainer } from "../../styles/common";
import { Container, BtnPrimary, Btn, BtnContinueShopping } from "./styles";

import UnableSendOrderMessage from "./components/UnableSendOrderMessage";

import BtnCancel from "../../components/BtnCancel";
import Parcelamento from "../../components/Parcelamento";
import { numberFormat } from "../../util/numberFormat";

import OrderItemsList from "../../components/OrderItemsList";
import OrderSendError from "./components/OrderSendError";

class Order extends Component {
  static propTypes = {
    getOrderRequest: PropTypes.func.isRequired,
    sendItemsRequest: PropTypes.func.isRequired,
    setItemsToSend: PropTypes.func.isRequired,
    cancelOrderRequest: PropTypes.func.isRequired,
    closeOrderRequest: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    sending: PropTypes.bool,
    canceling: PropTypes.bool,
    closing: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
    itemsToSend: PropTypes.arrayOf(PropTypes.shape().isRequired),
    nrOrder: PropTypes.number,
    perfumaria: PropTypes.string.isRequired,
    medicamentos: PropTypes.string.isRequired,
    liquid: PropTypes.string.isRequired,
    total: PropTypes.string.isRequired,
    canClose: PropTypes.bool,
    minimumReached: PropTypes.bool.isRequired,
    unableSendOrderMessage: PropTypes.arrayOf(PropTypes.string).isRequired,
    itemError: PropTypes.arrayOf(
      PropTypes.shape({
        codigoErro: PropTypes.number.isRequired,
        mensagem: PropTypes.string.isRequired,
        mercadoria: PropTypes.number.isRequired,
        pedido: PropTypes.number.isRequired,
      })
    ).isRequired,
  };

  static defaultProps = {
    nrOrder: null,
    itemsToSend: [],
    sending: false,
    canceling: false,
    closing: false,
    canClose: false,
  };

  componentDidMount() {
    const { getOrderRequest } = this.props;

    getOrderRequest();
  }

  getAddButtonText = () => {
    const { itemsToSend } = this.props;

    return !itemsToSend || (itemsToSend && itemsToSend.length === 0)
      ? "Nenhum item para atualizar"
      : `Atualizar ${itemsToSend.length} ite${
          itemsToSend.length > 1 ? "ns" : "m"
        } do pedido`;
  };

  setQt = (product, qtd) => {
    const newProduct = { ...product, qtd };
    let { itemsToSend } = this.props;
    const { setItemsToSend } = this.props;

    itemsToSend = itemsToSend.filter(
      (item) => product.cd_mercadoria !== item.cd_mercadoria
    );

    const maxQtd =
      newProduct.mercadoria.infos.qt_fisica -
      newProduct.mercadoria.infos.qt_reservada;

    if (qtd > maxQtd) {
      newProduct.qtd = maxQtd;
    }
    if (product.mercadoria.bloqueio) {
      const availableQtd =
        product.mercadoria.bloqueio.qt_limita_venda -
        product.mercadoria.bloqueio.qt_pedido_dia -
        product.mercadoria.bloqueio.qt_vendida;
      if (qtd > availableQtd) {
        newProduct.qtd = availableQtd;
      }
    }

    itemsToSend.push(newProduct);

    setItemsToSend(itemsToSend);
  };

  delItem = (product, event) => {
    if (event.target.checked) {
      this.setQt(product, 0);
    } else {
      let { itemsToSend } = this.props;
      const { setItemsToSend } = this.props;

      itemsToSend = itemsToSend.filter(
        (item) => product.cd_mercadoria !== item.cd_mercadoria
      );

      setItemsToSend(itemsToSend);
    }
  };

  sendItems = (event) => {
    event.preventDefault();

    const { sendItemsRequest, itemsToSend } = this.props;

    const arrPrepared = [];
    itemsToSend.forEach((item) => {
      arrPrepared.push({
        mercadoria: item.mercadoria.full_cd_mercadoria,
        qtd: parseInt(item.qtd, 10),
      });
    });

    sendItemsRequest(arrPrepared, false);
  };

  cancelOrder = (event) => {
    event.preventDefault();
    const { cancelOrderRequest } = this.props;
    cancelOrderRequest();
  };

  closeOrder = (event) => {
    event.preventDefault();
    const { closeOrderRequest } = this.props;
    closeOrderRequest();
  };

  render() {
    const {
      nrOrder,
      loading,
      items,
      sending,
      itemsToSend,
      canceling,
      closing,
      perfumaria,
      medicamentos,
      liquid,
      total,
      canClose,
      minimumReached,
      unableSendOrderMessage,
      itemError,
    } = this.props;

    if (nrOrder > 0) {
      setTitle(`Pedido #${nrOrder}`);
    } else {
      setTitle("Pedido");
    }

    return (
      <Container onSubmit={this.sendItems}>
        <h1>Pedido {nrOrder > 0 && `#${nrOrder}`}</h1>

        {loading && (
          <Loader type="ThreeDots" color="#ccc" height={50} width={50} />
        )}
        {items.length === 0 ? (
          <p>Nenhum item no seu pedido</p>
        ) : (
          <>
            <OrderSendError itemError={itemError} />

            <OrderItemsList
              loading={loading}
              perfumaria={perfumaria}
              medicamentos={medicamentos}
              liquid={liquid}
              total={total}
              minimumReached={minimumReached}
              items={items}
              itemsToSend={itemsToSend}
              setQt={this.setQt}
              delItem={this.delItem}
            />

            <Parcelamento
              total={total}
              isCampanhaSolar={isCampanhaSolar.get()}
            />

            <ButtonsContainer>
              <p>
                <BtnCancel disabled={canceling} onClick={this.cancelOrder}>
                  {canceling ? (
                    <LoaderContainer>
                      <Loader
                        type="ThreeDots"
                        color="rgba(33, 56, 128, 0.5)"
                        height={15}
                        width={30}
                      />
                      <span>enviando...</span>
                    </LoaderContainer>
                  ) : (
                    "Cancelar pedido"
                  )}
                </BtnCancel>
                <BtnContinueShopping to="/">
                  Continuar comprando
                </BtnContinueShopping>
                <Btn
                  type="submit"
                  disabled={
                    !itemsToSend || (itemsToSend && itemsToSend.length === 0)
                  }
                >
                  {sending ? (
                    <LoaderContainer>
                      <Loader
                        type="ThreeDots"
                        color="rgba(255, 255, 255, 0.5)"
                        height={15}
                        width={30}
                      />
                      <span>enviando...</span>
                    </LoaderContainer>
                  ) : (
                    this.getAddButtonText()
                  )}
                </Btn>

                <BtnPrimary
                  disabled={closing || !canClose}
                  onClick={this.closeOrder}
                >
                  {closing ? (
                    <LoaderContainer>
                      <Loader
                        type="ThreeDots"
                        color="rgba(255, 255, 255, 0.5)"
                        height={15}
                        width={30}
                      />
                      <span>finalizando...</span>
                    </LoaderContainer>
                  ) : (
                    "Finalizar pedido"
                  )}
                </BtnPrimary>
              </p>
              <UnableSendOrderMessage messages={unableSendOrderMessage} />
            </ButtonsContainer>
          </>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    order: {
      data: {
        clientInfo: {
          session,
          comBloqueio,
          mensagens_bloqueio: mensagensBloqueio,
        },
      },
    },
  } = state;

  const canClose =
    session.bloqueio_pedido.podeFechar && state.order.minimumReached;

  let unableSendOrderMessage = [];

  if (!canClose) {
    if (comBloqueio) {
      unableSendOrderMessage = mensagensBloqueio;
    } else if (!state.order.minimumReached) {
      const minimum = numberFormat(
        state.order.data.clientInfo.session.cliente.vlrMinimo
      );
      unableSendOrderMessage.push(`Valor mínimo de ${minimum} não atingido`);
    }
  }

  return {
    nrOrder: parseInt(state.order.data.nr_pedido || 0, 10),
    items: state.order.data.items,
    itemError: state.order.itemError,
    loading: state.order.loading,
    sending: state.order.sending,
    canceling: state.order.canceling,
    closing: state.order.closing,
    itemsToSend: state.order.itemsToSend,
    medicamentos: state.order.totals.medicamentos,
    perfumaria: state.order.totals.perfumaria,
    liquid: state.order.totals.liquid,
    total: state.order.totals.total,
    minimumReached: state.order.minimumReached,
    unableSendOrderMessage,
    canClose,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(OrderActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Order);
