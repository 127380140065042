import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const UnableSendOrderMessage = ({ messages }) => (
  <Container>{messages.length > 0 && messages.map(item => <li>{item}</li>)}</Container>
);

UnableSendOrderMessage.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default UnableSendOrderMessage;
