export const Types = {
  CATEGORY_REQUEST: 'category/CATEGORY_REQUEST',
  CATEGORY_SUCCESS: 'category/CATEGORY_SUCCESS',
  CATEGORY_FAILURE: 'category/CATEGORY_FAILURE',
};

const INITIAL_STATE = {
  loading: true,
  loaded: false,
  data: [],
  error: null,
};

export default function category(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CATEGORY_REQUEST:
      return { ...state, loading: true };
    case Types.CATEGORY_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        loaded: true,
        error: null,
      };
    case Types.CATEGORY_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        loaded: true,
        error: action.payload.message,
      };
    default:
      return state;
  }
}

export const Creators = {
  categoryRequest: () => ({
    type: Types.CATEGORY_REQUEST,
  }),

  categorySuccess: data => ({
    type: Types.CATEGORY_SUCCESS,
    payload: { data },
  }),

  categoryFailure: message => ({
    type: Types.CATEGORY_FAILURE,
    payload: { message },
  }),
};
