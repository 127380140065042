import { numberFormat } from '../../util/numberFormat';

export const Types = {
  KIT_PRODUCT_REQUEST: 'kit/KIT_PRODUCT_REQUEST',
  KIT_PRODUCT_SUCCESS: 'kit/KIT_PRODUCT_SUCCESS',
  KIT_PRODUCT_FAILURE: 'kit/KIT_PRODUCT_FAILURE',
};

const INITIAL_STATE = {
  loading: true,
  data: [],
  error: null,
};

export default function kit(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.KIT_PRODUCT_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
      };
    case Types.KIT_PRODUCT_SUCCESS:
      return {
        ...state,
        data: action.payload.data.map(product => (
          {
            ...product,
            formattedPreco: numberFormat(parseFloat(product.preco)),
            formattedDesconto: `${numberFormat(product.desconto)}%`,
          }
        )),
        loading: false,
        error: null,
      };
    case Types.KIT_PRODUCT_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.message,
      };
    default:
      return state;
  }
}

export const Creators = {
  kitRequest: (payload = null) => ({
    type: Types.KIT_PRODUCT_REQUEST,
    payload,
  }),

  kitSuccess: data => ({
    type: Types.KIT_PRODUCT_SUCCESS,
    payload: { data },
  }),

  kitFailure: message => ({
    type: Types.KIT_PRODUCT_FAILURE,
    payload: { message },
  }),
};
