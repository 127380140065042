import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles({
  card: {
    '& .MuiCardHeader-root': {
      padding: '0.25rem',
    },
    '& .MuiCardHeader-action': {
      margin: 0,
    },
    '& .MuiCardContent-root': {
      padding: '0.25rem',
    },
  },
  infoTable: {
    '& .MuiTableCell-root': {
      border: 'none',
      padding: '0 0.5rem',
    },
  },
  priceTable: {
    border: '1px solid rgba(224, 224, 224, 1)',
    '& .MuiTableHead-root': {
      backgroundColor: 'rgba(224, 224, 224, 1)',
    },
  },
});

const infoProperties = [
  { key: 'nr_ncm', label: 'NCM' },
  { key: 'ds_lista', label: 'Lista' },
  { key: 'nr_cest_mercadoria', label: 'CEST' },
  { key: 'ds_marca', label: 'Ind/Marca' },
  { key: 'ds_tipo_mercadoria', label: 'Tipo' },
  { key: 'ds_grupo_categoria', label: 'Grupo de categoria' },
  { key: 'ds_categoria', label: 'Categoria' },
  { key: 'ds_subcategoria', label: 'Subcategoria' },
  { key: 'ean_venda_mercadoria', label: 'Código de barras' },
];

function ProductInfo({ product }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? `product-${product.cd_mercadoria}-popover` : undefined;

  return (
    <>
      <IconButton onClick={handleClick} type="button">
        <InfoIcon color="primary" />
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Card className={classes.card}>
          <CardHeader
            disableTypography
            action={(
              <IconButton size="small" onClick={handleClose} aria-label="close">
                <CloseIcon fontSize="small" />
              </IconButton>
            )}
            title={(
              <Typography variant="subtitle2">
                {product.ds_apresentacao}
              </Typography>
            )}
          />
          <Divider />
          <CardContent>
            <Table className={classes.infoTable} size="small">
              <TableBody>
                {infoProperties.map(propertie => (
                  <TableRow key={propertie.key}>
                    <TableCell align="right">
                      <Typography variant="subtitle2">{propertie.label}:</Typography>
                    </TableCell>
                    <TableCell>{product[propertie.key]}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="right">
                    <Typography variant="subtitle2">Embalagens:</Typography>
                  </TableCell>
                  <TableCell>
                    {product.embalagens?.map(emabalagem => `${emabalagem?.ds_tipo || ''} ${emabalagem?.qt_embalagem || ''}`).join(',')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Typography variant="subtitle2">Preço</Typography>

            <Table className={classes.priceTable} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Preço Líquido</TableCell>
                  <TableCell>Imposto</TableCell>
                  <TableCell>Líquido + Imposto</TableCell>
                  <TableCell>PMC</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{product.infos.vl_preco_liq_formated}</TableCell>
                  <TableCell>{product.infos.vl_imposto_formated}</TableCell>
                  <TableCell>{product.infos.vl_total_formated}</TableCell>
                  <TableCell>
                    {product.infos.vl_preco_maximo_consumidor_formated}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
}

ProductInfo.propTypes = {
  product: PropTypes.shape({
    cd_mercadoria: PropTypes.number.isRequired,
    nr_dv_mercadoria: PropTypes.number.isRequired,
    ds_apresentacao: PropTypes.string.isRequired,
    id_imagem: PropTypes.string.isRequired,
    id_tipo_mercadoria: PropTypes.string.isRequired,
    ean_venda_mercadoria: PropTypes.string.isRequired,
    cd_fornecedor: PropTypes.string.isRequired,
    uf_kit: PropTypes.string,
    cd_fabricante_pai: PropTypes.string.isRequired,
    id_generico: PropTypes.string.isRequired,
    infos: PropTypes.shape({
      cd_mercadoria: PropTypes.number.isRequired,
      cd_empresa: PropTypes.number.isRequired,
      cd_projeto: PropTypes.number.isRequired,
      qt_fisica: PropTypes.number.isRequired,
      dt_atualizacao: PropTypes.string.isRequired,
      qt_reservada: PropTypes.number.isRequired,
      vl_preco_liq: PropTypes.number.isRequired,
      vl_imposto: PropTypes.number.isRequired,
      id_campanha_solar: PropTypes.string.isRequired,
      id_simples: PropTypes.string.isRequired,
      pc_desconto_financeiro: PropTypes.number.isRequired,
      vl_desconto_financeiro: PropTypes.number.isRequired,
      vl_preco_maximo_consumidor: PropTypes.number.isRequired,
      vl_preco_fabrica: PropTypes.number.isRequired,
      id_falta: PropTypes.string.isRequired,
      vl_desconto_nota: PropTypes.number.isRequired,
      pc_desconto_nota: PropTypes.number.isRequired,
      vl_total_sem_st: PropTypes.number.isRequired,
      vl_total: PropTypes.number.isRequired,
      vl_preco_liq_formated: PropTypes.string.isRequired,
      vl_imposto_formated: PropTypes.string.isRequired,
      vl_total_formated: PropTypes.string.isRequired,
      vl_preco_fabrica_formated: PropTypes.string.isRequired,
      vl_desconto_financeiro_formated: PropTypes.string.isRequired,
      vl_desconto_nota_formated: PropTypes.string.isRequired,
      vl_total_sem_st_formated: PropTypes.string.isRequired,
      vl_preco_maximo_consumidor_formated: PropTypes.string.isRequired,
    }).isRequired,
    url_imagem: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.bool.isRequired,
    ]),
    full_cd_mercadoria: PropTypes.string.isRequired,
    embalagem: PropTypes.shape({
      ds_tipo: PropTypes.string.isRequired,
      qt_embalagem: PropTypes.number.isRequired,
    }),
  }).isRequired,
};

export default ProductInfo;
