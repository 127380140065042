import axios from 'axios';
import { getClientData } from './auth';

export const baseURLAPI = process.env.REACT_APP_URL_EGAM_API;

const egam = axios.create({
  baseURL: baseURLAPI,
});

egam.interceptors.request.use(async (config) => {
  const clientData = getClientData();
  const { ds_token_egam: token } = clientData;

  const newConfig = config;
  if (token) {
    newConfig.headers.Authorization = `Bearer ${token}`;
  }
  return newConfig;
});

export default egam;
