import { call, put } from 'redux-saga/effects';
import api from '../../services/api';

import { Creators as CategoriesActions } from '../ducks/categories';

export function* getCategories() {
  try {
    let response = null;
    response = yield call(api.get, 'products/categories');

    if (response.status !== 200) {
      yield put(CategoriesActions.categoryFailure('Problema ao buscar itens'));
    } else {
      yield put(CategoriesActions.categorySuccess(response.data));
    }
  } catch (err) {
    yield put(CategoriesActions.categoryFailure('Problema ao buscar itens'));
  }
}
