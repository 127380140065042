import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { ClockContainer, ClockIcon } from './styles';

const Clock = ({ hour, canSendOrders }) => (
  <ClockContainer>
    <ClockIcon open={canSendOrders} />
    <p>{hour || '--:--:--'}</p>
  </ClockContainer>
);

Clock.propTypes = {
  hour: PropTypes.string.isRequired,
  canSendOrders: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  hour: state.clock.hour,
  canSendOrders: state.clock.canSendOrders,
});

export default connect(mapStateToProps)(Clock);
