import { call, put } from 'redux-saga/effects';
import api from '../../services/api';
import { login as persistLogin, logout, client } from '../../services/auth';

import { Creators as LoginActions } from '../ducks/login';

export function* login(action) {
  const { token, coreIndustry } = action.payload;
  try {
    yield call(persistLogin, token);

    const response = yield call(api.post, 'cliente/valida-sessao', { coreIndustry });

    if (response.status !== 200) {
      logout();
      yield put(LoginActions.loginFailure('Não foi possível autenticar'));
    } else {
      yield call(client, response.data);
      yield put(LoginActions.loginSuccess(response.data));
    }
  } catch (err) {
    yield put(LoginActions.loginFailure('Não foi possível autenticar'));
  }
}
