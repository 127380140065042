import React from 'react';
import PropTypes from 'prop-types';

import { Container, ErrorList } from './styles';

const OrderSendError = ({ itemError }) => (
  <Container id="errorList">
    {
      itemError.length > 0
        && (
          <>
            <ErrorList>
              {itemError.map(item => (
                <li key={item.mercadoria}>
                  Erro ao processar mercadoria {item.mercadoria}: {item.mensagem}
                </li>
              ))}
            </ErrorList>
          </>
        )
    }
  </Container>
);

OrderSendError.propTypes = {
  itemError: PropTypes.arrayOf(PropTypes.shape({
    codigoErro: PropTypes.number.isRequired,
    mensagem: PropTypes.string.isRequired,
    mercadoria: PropTypes.number.isRequired,
    pedido: PropTypes.number.isRequired,
  })).isRequired,
};

export default OrderSendError;
