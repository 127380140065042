import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, withRouter } from 'react-router-dom';

import './config/reactotron';

import { ToastContainer } from 'react-toastify';
import Routes from './routes';
import store from './store';

import Header from './components/Header';
import NavBar from './components/Navbar';
import Search from './components/Search';
import Body from './components/Body';
import Footer from './components/Footer';

import 'react-toastify/dist/ReactToastify.min.css';

import GlobalStyle from './styles/global';

const SearchBar = withRouter(({ history }) => <Search history={history} />);

const App = () => (
  <Provider store={store}>
    <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
      <GlobalStyle />
      <Header />
      <NavBar />
      <SearchBar />
      <Body>
        <ToastContainer
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable
          pauseOnHover
          className="toast"
        />
        <Routes />
      </Body>
      <Footer />
    </BrowserRouter>
  </Provider>
);

export default App;
