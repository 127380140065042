import React from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";

import { IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

import { BloqueioDescriptionText, BloqueioInfoLink } from "./style";

function BloqueioIndicator(props) {
  const {
    product,
    modalBloqueioIsOpen,
    closeModalBloqueio,
    openModalBloqueio,
  } = props;

  return (
    <>
      <Modal
        isOpen={modalBloqueioIsOpen}
        className="modal"
        onRequestClose={closeModalBloqueio}
        contentLabel="Observação"
        style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
      >
        <div className="head">
          <h2>Produto com bloqueio</h2>
          <IconButton onClick={closeModalBloqueio} type="button">
            <ClearIcon />
          </IconButton>
        </div>
        <div className="content">
          <div>
            <BloqueioDescriptionText>
              Venda limitada a quantidade máxima de{" "}
              {product.bloqueio.qt_limita_venda} produtos.
            </BloqueioDescriptionText>
            <BloqueioDescriptionText>
              {product.bloqueio.qt_disponivel < 0
                ? "Limite atingido"
                : `Quantidade disponível: ${product.bloqueio.qt_disponivel} ${
                    product.bloqueio.qt_disponivel > 1 ? "produtos" : "produto"
                  }.`}
            </BloqueioDescriptionText>
          </div>
          <div>
            <button onClick={closeModalBloqueio} type="button">
              Ok
            </button>
          </div>
        </div>
      </Modal>
      <em>
        (
        <BloqueioInfoLink onClick={openModalBloqueio}>
          {product.bloqueio.qt_disponivel < 0
            ? "Limite atingido"
            : `Quantidade disponível: ${product.bloqueio.qt_disponivel}`}
        </BloqueioInfoLink>
        )
      </em>
    </>
  );
}

BloqueioIndicator.propTypes = {
  product: PropTypes.shape({
    ds_apresentacao: PropTypes.string.isRequired,
    url_imagem: PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.bool.isRequired,
    ]),
    id_tipo_mercadoria: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    infos: PropTypes.shape({
      pc_desconto_financeiro: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      id_falta: PropTypes.string.isRequired,
    }).isRequired,
    bloqueios: PropTypes.shape({
      cd_conexao: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      cd_mercadoria: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      qt_limita_venda: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      qt_pedido_dia: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      qt_vendida: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  }).isRequired,
  modalBloqueioIsOpen: PropTypes.bool.isRequired,
  closeModalBloqueio: PropTypes.func.isRequired,
  openModalBloqueio: PropTypes.func.isRequired,
};

export default BloqueioIndicator;
