export const CondicoesDeParcelamentoFixo = {
  genericos: [
    { description: "1x 30 dias", cdParcela: 30 },
    { description: "1x 60 dias", cdParcela: 60 },
    {
      description: "2x 30 e 60 dias",
      cdParcela: 10246,
      dias: [30, 60],
    },
    {
      description: "3x 30, 60 e 90 dias",
      cdParcela: 10245,
      dias: [30, 60, 90],
    },
  ],
  solar: {
    "30/60/90": [
      {
        description: "3x 30, 60 e 90 dias",
        cdParcela: 10245,
        dias: [30, 60, 90],
      },
    ],
    "30/60": [
      {
        description: "2x 30 e 60 dias",
        cdParcela: 10246,
        dias: [30, 60],
      },
    ],
    "60/90": [
      {
        description: "2x 60 e 90 dias",
        cdParcela: 10711,
        dias: [60, 90],
      },
    ],
    "60/90/120": [
      { description: "1x 60 dias", cdParcela: 60 },
      {
        description: "2x 60 e 90 dias",
        cdParcela: 10711,
        dias: [60, 90],
      },
      {
        description: "3x 60, 90 e 120 dias",
        cdParcela: 10712,
        dias: [60, 90, 120],
      },
    ],
    "1x60/90/120": [
      {
        description: "3x 60, 90 e 120 dias",
        cdParcela: 10712,
        dias: [60, 90, 120],
      },
    ],
    "90/120/150": [
      {
        description: "3x 90, 120 e 150 dias",
        cdParcela: 10713,
        dias: [90, 120, 150],
      },
    ],
    "28/56/84": [
      {
        description: "3x 28, 56 e 84 dias",
        cdParcela: 10740,
        dias: [28, 56, 84],
      },
    ],
    "120/150/180": [
      {
        description: "3x 120, 150 e 180 dias",
        cdParcela: 10741,
        dias: [120, 150, 180],
      },
    ],
  },
  especial: {
    "33/40/47": [
      {
        description: "3x 33, 40 e 47 dias",
        cdParcela: 10027,
        dias: [33, 40, 47],
      },
    ],
    "30/60/90": [
      {
        description: "3x 30, 60 e 90 dias",
        cdParcela: 10245,
        dias: [30, 60, 90],
      },
    ],
    "30/60/90/120": [
      {
        description: "4x 30, 60, 90 e 120 dias",
        cdParcela: 10247,
        dias: [30, 60, 90, 120],
      },
    ],
  },
};

export default CondicoesDeParcelamentoFixo;
