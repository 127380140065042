import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import FileImporter from '../../components/OrderImport/FileImporter';
import OrderImportProvider from '../../components/OrderImport/OrderImportContext';
import { setTitle } from '../../services/browser';

export default function OrderImport() {
  useEffect(() => {
    setTitle('Importar arquivo de pedido');
  }, []);

  return (
    <Box display="flex" flexDirection="column" style={{ gap: '0.75rem' }}>
      <h1>Importar arquivo de pedido</h1>

      <OrderImportProvider>
        <FileImporter />
      </OrderImportProvider>
    </Box>
  );
}
