import React from 'react';

import { Container } from './styles';

const NoProductResult = ({ ...rest }) => (
  <Container>
    <td {...rest}>Nenhum produto encontrado</td>
  </Container>
);

export default NoProductResult;
