import {
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ProductsActions } from '../../../store/ducks/products';
import { FilterSelect } from './FilterSelect';

const FilterProductType = () => {
  const dispatch = useDispatch();
  const { data: categories } = useSelector(state => state.categories);
  const selected = useSelector(state => state.products.searchProductType);

  const productsTypes = Object.values(categories.reduce((acc, category) => {
    if (!acc[category.cod_type]) {
      acc[category.cod_type] = {
        cod: category.cod_type,
        description: category.type,
      };
    }

    return acc;
  }, {}));

  const onChange = (event) => {
    const value = event.target.value !== '0' ? event.target.value : '*';

    dispatch(ProductsActions.setSearchProductType(value));
    dispatch(ProductsActions.setSearchCategoryGroup('*'));
    dispatch(ProductsActions.setSearchCategory('*'));
  };

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel>Tipo:</InputLabel>
      <FilterSelect
        value={selected}
        onChange={onChange}
        label="Tipo"
      >
        <MenuItem value="*">
          Todas as opções
        </MenuItem>
        {
          productsTypes.map(productType => (
            <MenuItem
              key={productType.cod}
              value={productType.cod}
              selected={selected === productType.cod}
            >
              {productType.description}
            </MenuItem>
          ))
        }
      </FilterSelect>
    </FormControl>
  );
};

export default FilterProductType;
