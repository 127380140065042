import styled from 'styled-components';

export const Container = styled.tr`
  text-align: center;

  td {
    color: #666;
    padding: 10px;
  }
`;
