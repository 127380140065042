import { all, takeLatest, spawn, takeLeading } from "redux-saga/effects";

import { Types as LoginTypes } from "../ducks/login";
import { Types as ProductTypes } from "../ducks/products";
import { Types as IndustriesTypes } from "../ducks/industries";
import { Types as CategoriesTypes } from "../ducks/categories";
import { Types as OrderTypes } from "../ducks/order";
import { Types as ClockTypes } from "../ducks/clock";
import { Types as ParcelamentoTypes } from "../ducks/parcelamento";
import { Types as KitTypes } from "../ducks/kit";

import { login } from "./login";
import { getProducts } from "./products";
import { getIndustries } from "./industries";
import { getCategories } from "./categories";
import { getOrder, sendItems, cancelOrder, closeOrder } from "./order";
import { clock, startClock } from "./clock";
import {
  getParcelamento,
  getIntervalo,
  getPrazoMedio,
  getCondicoes,
  calculaParcelas,
  getParcelamentoNormal,
  calculateMinimum,
} from "./parcelamento";
import { getKitProducts } from "./kit";

export default function* rootSaga() {
  yield all([
    takeLatest(LoginTypes.LOGIN_REQUEST, login),
    takeLatest(IndustriesTypes.INDUSTRY_REQUEST, getIndustries),
    takeLatest(CategoriesTypes.CATEGORY_REQUEST, getCategories),
    takeLatest(ProductTypes.PRODUCT_REQUEST, getProducts),
    takeLatest(OrderTypes.GET_ORDER_REQUEST, getOrder),
    takeLatest(OrderTypes.CANCEL_ORDER_REQUEST, cancelOrder),
    takeLatest(ParcelamentoTypes.GET_PARCELAMENTO_REQUEST, getParcelamento),
    takeLatest(
      ParcelamentoTypes.GET_PARCELAMENTO_NORMAL,
      getParcelamentoNormal
    ),
    takeLatest(ParcelamentoTypes.GET_INTERVALO_REQUEST, getIntervalo),
    takeLatest(ParcelamentoTypes.GET_PRAZO_MEDIO_REQUEST, getPrazoMedio),
    takeLatest(ParcelamentoTypes.GET_CONDICOES_REQUEST, getCondicoes),
    takeLatest(ParcelamentoTypes.SET_PARCELAMENTO_GENERICO, calculaParcelas),
    takeLatest(ParcelamentoTypes.SET_PARCELAMENTO_ESPECIAL, calculaParcelas),
    takeLatest(ParcelamentoTypes.SET_PARCELAMENTO_SOLAR, calculaParcelas),
    takeLatest(ParcelamentoTypes.CALCULATE_MINIMUM, calculateMinimum),
    takeLeading(OrderTypes.CLOSE_ORDER_REQUEST, closeOrder),
    takeLatest(OrderTypes.SEND_ITEMS_REQUEST, sendItems),
    takeLatest(ClockTypes.CLOCK_TRUCK_REQUEST, clock),
    takeLatest(KitTypes.KIT_PRODUCT_REQUEST, getKitProducts),

    spawn(startClock),
  ]);
}
