export const Types = {
  LOGIN_REQUEST: 'login/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'login/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'login/LOGIN_FAILURE',
};

const INITIAL_STATE = {
  loading: true,
  loggedIn: false,
  error: null,
  data: null,
};

export default function login(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LOGIN_REQUEST:
      return { ...state, loading: true };
    case Types.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        data: action.payload.data,
        loading: false,
        error: null,
      };
    case Types.LOGIN_FAILURE:
      return { data: null, loading: false, error: action.payload.message };
    default:
      return state;
  }
}


export const Creators = {
  loginRequest: (token, coreIndustry) => ({
    type: Types.LOGIN_REQUEST,
    payload: { token, coreIndustry },
  }),

  loginSuccess: data => ({
    type: Types.LOGIN_SUCCESS,
    payload: { data },
  }),

  loginFailure: message => ({
    type: Types.LOGIN_FAILURE,
    payload: { message },
  }),
};
