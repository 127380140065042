import React, { Fragment } from 'react';

// import { Container } from './styles';

const Unauthorized = () => (
  <Fragment>
    <h1>Você não está autenticado!</h1>
    <p>Volte ao eGAM e entre novamente</p>
  </Fragment>
);

export default Unauthorized;
