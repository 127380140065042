import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';

import {
  Container,
  OrderContainer,
  IconProductType,
  LiquidContainer,
  GrossContainer,
} from './styles';

const Footer = ({
  nrOrder, perfumaria, medicamentos, liquid, kit, total, loading, minimumReached,
}) => (
  <Container>
    <OrderContainer>
      <div>
        <Link to="/pedido">
          <strong>Seu pedido:</strong>
          <span>{nrOrder !== '' ? `#${nrOrder}` : ''}</span>
        </Link>
        {loading && <Loader type="ThreeDots" color="rgba(0, 0, 0, 0.5)" height={15} width={30} />}
      </div>
    </OrderContainer>
    <IconProductType color="#f00">
      <p>
        <strong>Perfumaria:</strong>
        <span>{perfumaria}</span>
      </p>
    </IconProductType>
    <IconProductType color="#ADC295">
      <p>
        <strong>Kit:</strong>
        <span>{kit}</span>
      </p>
    </IconProductType>
    <IconProductType color="#3f0">
      <p>
        <strong>Medicamentos:</strong>
        <span>{medicamentos}</span>
      </p>
    </IconProductType>
    <LiquidContainer>
      <p>
        <strong>Líquido:</strong>
        <span className={!minimumReached ? 'minimumNotReached' : ''}>{liquid}</span>
      </p>
    </LiquidContainer>
    <GrossContainer>
      <p>
        <strong>Total:</strong>
        <span>{total}</span>
      </p>
    </GrossContainer>
  </Container>
);

Footer.propTypes = {
  nrOrder: PropTypes.string,
  perfumaria: PropTypes.string.isRequired,
  medicamentos: PropTypes.string.isRequired,
  liquid: PropTypes.string.isRequired,
  kit: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumReached: PropTypes.bool.isRequired,
};

Footer.defaultProps = {
  nrOrder: '',
};

const mapStateToProps = state => ({
  nrOrder: `${state.order.data.nr_pedido}`,
  loading: state.order.loading,
  medicamentos: state.order.totals.medicamentos,
  perfumaria: state.order.totals.perfumaria,
  liquid: state.order.totals.liquid,
  kit: state.order.totals.kit,
  total: state.order.totals.total,
  minimumReached: state.order.minimumReached,
});

export default connect(mapStateToProps)(Footer);
