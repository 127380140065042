export const TOKEN_KEY = '@egam2b-Token';
export const CLIENTE_KEY = '@egam2b-Cliente';
export const SOLAR_KEY = '@egam2b-Solar';
export const PEDIDO_KEY = '@egam2b-Pedido';

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getClientData = () => JSON.parse(localStorage.getItem(CLIENTE_KEY));

export const login = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const client = (data) => {
  localStorage.setItem(CLIENTE_KEY, JSON.stringify(data));
};

export const setNrPedidoAtivo = (nrPedido) => {
  if (nrPedido === null) {
    localStorage.removeItem(PEDIDO_KEY);
  } else {
    localStorage.setItem(PEDIDO_KEY, nrPedido);
  }
};

export const getNrPedidoAtivo = () => localStorage.getItem(PEDIDO_KEY);

export const campanhaSolar = (status) => {
  if (status === true) {
    localStorage.setItem(SOLAR_KEY, true);
  } else {
    localStorage.removeItem(SOLAR_KEY);
  }
};

export const isCampanhaSolar = {
  get: () => localStorage.getItem(SOLAR_KEY) !== null,
};
