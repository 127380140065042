import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ParcelamentoContainer, ParcelamentoLabel } from '../style';
import { Creators as ParcelamentoActions } from '../../../store/ducks/parcelamento';

class DefaultParcelamento extends Component {
  componentDidMount() {
    const {
      getParcelamentoNormal,
    } = this.props;

    getParcelamentoNormal();
  }

  changeParcelas = (value) => {
    const { setParcelas, getIntervaloRequest } = this.props;

    setParcelas(value);

    if (value !== '') {
      getIntervaloRequest();
    }
  };

  changeIntervalo = (value) => {
    const { setIntervalos, getPrazoMedioRequest } = this.props;

    setIntervalos(value);

    if (value !== '') {
      getPrazoMedioRequest();
    }
  }

  changePrazoMedio = (value) => {
    const { setPrazoMedio, getCondicoesRequest } = this.props;

    setPrazoMedio(value);

    if (value !== '') {
      getCondicoesRequest();
    }
  }

  render() {
    const {
      parcela,
      parcelas,
      intervalo,
      intervalos,
      prazo,
      prazoMedio,
    } = this.props;

    return (
      <ParcelamentoContainer>
        <ParcelamentoLabel>
          <span>Parcelas:</span>
          <select
            name="parcelas"
            defaultValue={parcela}
            onChange={(event) => { this.changeParcelas(event.target.value); }}
            disabled={parcelas.length < 1}
          >
            <option value="">Selecione</option>
            {parcelas && parcelas.map(value => (
              <option key={value} value={value}>{value}</option>
            ))}
          </select>
        </ParcelamentoLabel>

        <ParcelamentoLabel>
          <span>Intervalo:</span>
          <select
            name="intervalo"
            defaultValue={intervalo}
            onChange={(event) => { this.changeIntervalo(event.target.value); }}
            disabled={intervalos.length < 1}
          >
            <option value="">Selecione</option>
            {intervalos && intervalos.map(value => (
              <option key={value} value={value}>{value} ({(value > 0 ? 'Parcelamento' : 'Divisão de boletos')})</option>
            ))}
          </select>
        </ParcelamentoLabel>
        <ParcelamentoLabel>
          <span>Prazo médio:</span>
          <select
            name="prazoMedio"
            defaultValue={prazo}
            onChange={(event) => { this.changePrazoMedio(event.target.value); }}
            disabled={prazoMedio.length < 1}
          >
            <option value="">Selecione</option>
            {prazoMedio && prazoMedio.map(value => (
              <option key={value} value={value}>{value} dias</option>
            ))}
          </select>
        </ParcelamentoLabel>
      </ParcelamentoContainer>
    );
  }
}

DefaultParcelamento.propTypes = {
  parcela: PropTypes.string.isRequired,
  parcelas: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  setParcelas: PropTypes.func.isRequired,
  setPrazoMedio: PropTypes.func.isRequired,
  getIntervaloRequest: PropTypes.func.isRequired,
  setIntervalos: PropTypes.func.isRequired,
  getPrazoMedioRequest: PropTypes.func.isRequired,
  getCondicoesRequest: PropTypes.func.isRequired,
  intervalos: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  intervalo: PropTypes.string.isRequired,
  prazoMedio: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])).isRequired,
  prazo: PropTypes.string.isRequired,
  getParcelamentoNormal: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(ParcelamentoActions, dispatch);

const mapStateToProps = state => ({
  parcelas: state.parcelamento.parcelas,
  parcela: state.parcelamento.parcela,
  intervalos: state.parcelamento.intervalos,
  intervalo: state.parcelamento.intervalo,
  prazoMedio: state.parcelamento.prazoMedio,
  prazo: state.parcelamento.prazo,
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultParcelamento);
