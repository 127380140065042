import React from 'react';
import { call, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import { SOLAR_KEY, setNrPedidoAtivo, getNrPedidoAtivo } from '../../services/auth';

import { Creators as OrderActions } from '../ducks/order';
import { Creators as ParcelamentoActions } from '../ducks/parcelamento';

export function* closeOrder() {
  try {
    const nrPedido = getNrPedidoAtivo() !== null ? `/${getNrPedidoAtivo()}` : '';
    const parcelamento = yield select(state => state.parcelamento);
    const response = yield call(api.put, `orders${nrPedido}`, {
      codigoParcelamento: parcelamento.codigoParcelamento,
      prazoMedio: parcelamento.prazo,
      numeroDiasVencimento: parcelamento.qtdDias,
    });

    if (response.status !== 200) {
      yield put(OrderActions.closeOrderFailure('Não foi possível finalizar o pedido'));
    } else if (parseFloat(response.data.errorCode) === 10) {
      toast.success('Seu pedido foi finalizado com sucesso! Acesse o eGAM para acompanhá-lo.');
      setNrPedidoAtivo(null);
      yield put(OrderActions.closeOrderSuccess({ message: 'Pedido já finalizado' }));
    } else if (parseFloat(response.data.pedidoFechado.codigoErro) === 0) {
      toast.success('Seu pedido foi finalizado com sucesso! Acesse o eGAM para acompanhá-lo.');
      setNrPedidoAtivo(null);
      yield put(OrderActions.closeOrderSuccess(response.data));
    } else {
      toast.error('Ocorreu um erro a finalizar o pedido.');
      yield put(OrderActions.closeOrderFailureAndShowMessage(response.data.itemErro));
    }
  } catch (err) {
    toast.error('Ocorreu um erro a finalizar o pedido.');
    yield put(OrderActions.closeOrderFailure('Não foi possível finalizar o pedido'));
  }
}

export function* cancelOrder() {
  try {
    const nrPedido = getNrPedidoAtivo() !== null ? `/${getNrPedidoAtivo()}` : '';
    const response = yield call(api.delete, `orders${nrPedido}`);

    if (response.status !== 200) {
      yield put(OrderActions.cancelOrderFailure('Não foi possível cancelar o pedido'));
    } else {
      toast.success('Seu pedido foi cancelado!');
      setNrPedidoAtivo(null);
      yield put(OrderActions.cancelOrderSuccess(response.data));
    }
  } catch (err) {
    yield put(OrderActions.cancelOrderFailure('Não foi possível cancelar o pedido'));
  }
}

export function* sendItems(action) {
  try {
    const isCampanhaSolar = localStorage.getItem(SOLAR_KEY) === false
      || localStorage.getItem(SOLAR_KEY) === null
      ? 'N'
      : 'S';
    const { items, sum } = action.payload;
    const response = yield call(api.put, 'orders/item', { items, sum, isCampanhaSolar });

    if (response.status !== 200) {
      yield put(OrderActions.sendItemsFailure('Não foi possível adicionar os itens'));
    } else {
      toast.success(
        <Link to="/pedido">Itens adicionados com sucesso! Clique aqui e veja seu pedido.</Link>,
      );
      yield put(ParcelamentoActions.resetParcelamento());
      yield put(OrderActions.sendItemsSuccess(response.data));
    }
  } catch (err) {
    toast.error(err.response.data.error);
    yield put(OrderActions.sendItemsFailure('Não foi possível adicionar os itens'));
  } finally {
    if (action.payload.callback) {
      action.payload.callback();
    }
  }
}

export function* getOrder() {
  try {
    const isCampanhaSolar = localStorage.getItem(SOLAR_KEY) === false
      || localStorage.getItem(SOLAR_KEY) === null
      ? 'N'
      : 'S';
    const response = yield call(api.get, `orders?id_campanha_solar=${isCampanhaSolar}`);

    if (response.status !== 200) {
      yield put(OrderActions.getOrderFailure('Não foi possível buscar os itens'));
    } else {
      setNrPedidoAtivo(response.data.nr_pedido);
      yield put(OrderActions.getOrderSuccess(response.data));
    }
  } catch (err) {
    yield put(OrderActions.getOrderFailure('Não foi possível buscar os itens'));
  }
}
