import {
  FormControl,
  InputLabel,
  MenuItem,
} from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ProductsActions } from '../../../store/ducks/products';
import { FilterSelect } from './FilterSelect';

const FilterCategory = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.categories);
  const categoryGroup = useSelector(state => state.products.searchCategoryGroup);
  const selected = useSelector(state => state.products.searchCategory);

  const categories = data
    .filter(category => category.group === categoryGroup)
    .map(category => category.category);

  const onChange = (event) => {
    const value = event.target.value !== '0' ? event.target.value : '*';

    dispatch(ProductsActions.setSearchCategory(value));
  };

  return (
    <FormControl size="small" variant="outlined" fullWidth>
      <InputLabel>Categoria:</InputLabel>
      <FilterSelect
        value={selected}
        onChange={onChange}
        label="Categoria"
      >
        <MenuItem value="*">
          Todos os níveis
        </MenuItem>
        {
          categories.map(category => (
            <MenuItem
              key={category}
              value={category}
              selected={selected === category}
            >
              {category}
            </MenuItem>
          ))
        }
      </FilterSelect>
    </FormControl>
  );
};

export default FilterCategory;
