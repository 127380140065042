export const Types = {
  INDUSTRY_REQUEST: 'industry/INDUSTRY_REQUEST',
  INDUSTRY_SUCCESS: 'industry/INDUSTRY_SUCCESS',
  INDUSTRY_FAILURE: 'industry/INDUSTRY_FAILURE',
};

const INITIAL_STATE = {
  loading: true,
  loaded: false,
  data: [],
  error: null,
};

export default function industry(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.INDUSTRY_REQUEST:
      return { ...state, loading: true };
    case Types.INDUSTRY_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        loaded: true,
        error: null,
      };
    case Types.INDUSTRY_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        loaded: true,
        error: action.payload.message,
      };
    default:
      return state;
  }
}

export const Creators = {
  industryRequest: () => ({
    type: Types.INDUSTRY_REQUEST,
  }),

  industrySuccess: data => ({
    type: Types.INDUSTRY_SUCCESS,
    payload: { data },
  }),

  industryFailure: message => ({
    type: Types.INDUSTRY_FAILURE,
    payload: { message },
  }),
};
