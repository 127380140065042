export const Types = {
  CLOCK_TRUCK_REQUEST: 'clock/CLOCK_TRUCK_REQUEST',
  CLOCK_TRUCK_SUCCESS: 'clock/CLOCK_TRUCK_SUCCESS',
  CLOCK_TRUCK_FAILURE: 'clock/CLOCK_TRUCK_FAILURE',

  CLOCK_SET_HOUR: 'clock/CLOCK_SET_HOUR',
};

const INITIAL_STATE = {
  loading: false,
  data: null,
  hour: '--:--:--',
  canSendOrders: false,
};

export default function clock(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.CLOCK_SET_HOUR: {
      return { ...state, hour: action.payload.hour, canSendOrders: action.payload.canSendOrders };
    }
    case Types.CLOCK_TRUCK_REQUEST:
      return { ...state, loading: true };
    case Types.CLOCK_TRUCK_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        error: null,
      };
    case Types.CLOCK_TRUCK_FAILURE:
      return { ...state, loading: false, error: action.payload.message };
    default:
      return state;
  }
}

export const Creators = {
  clockSetHour: (hour, canSendOrders) => ({
    type: Types.CLOCK_SET_HOUR,
    payload: { hour, canSendOrders },
  }),

  clockTruckRequest: token => ({
    type: Types.CLOCK_TRUCK_REQUEST,
    payload: { token },
  }),

  clockTruckSuccess: data => ({
    type: Types.CLOCK_TRUCK_SUCCESS,
    payload: { data },
  }),

  clockTruckFailure: message => ({
    type: Types.CLOCK_TRUCK_FAILURE,
    payload: { message },
  }),
};
