import { call, put } from 'redux-saga/effects';
import egam from '../../services/egam';

import { getClientData } from '../../services/auth';

import { Creators as KitActions } from '../ducks/kit';

export function* getKitProducts(action) {
  try {
    const cliente = getClientData();

    const formData = new FormData();
    formData.append('cliente', cliente.cd_cliente);

    let kit = action.payload.ufKit.find(kitUf => kitUf.uf === cliente.uf);

    if (cliente.cd_cliente === 16684) {
      if (!kit) {
        [kit] = action.payload.ufKit;
      }
    }

    const response = yield call(egam.post, `lista-produtos-kit/${kit.cd_kit}`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });

    if (response.status !== 200) {
      yield put(KitActions.kitFailure('Problema ao buscar itens'));
    } else {
      yield put(KitActions.kitSuccess(response.data));
    }
  } catch (err) {
    yield put(KitActions.kitFailure('Problema ao buscar itens'));
  }
}
