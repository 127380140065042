import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Main from '../Main';
import { SOLAR_KEY } from '../../services/auth';

const SearchResult = ({ match, industries, ...rest }) => {
  let title = 'Resultado de pesquisa para ';

  if (match.params.term && match.params.term !== '*') {
    title = `${title} '${match.params.term}'`;
  } else {
    title = `${title} todos os produtos`;
  }

  if (match.params.industry && match.params.industry !== '*') {
    let name = industries.find(item => item.cd_fornecedor === match.params.industry);

    name = name ? name.ds_fornecedor : '...';

    title = `${title} no laboratório '${name}'`;
  } else {
    title = `${title} de todos os laboratórios`;
  }

  if (localStorage.getItem(SOLAR_KEY)) {
    title = `${title} na campanha Solar`;
  }

  return <Main {...rest} match={match} title={title} />;
};

SearchResult.propTypes = {
  industries: PropTypes.arrayOf({
    cd_fornecedor: PropTypes.number.isRequired,
    ds_fornecedor: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape().isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  industries: state.industries.data,
});

export default connect(mapStateToProps)(SearchResult);
