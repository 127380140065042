import { TextField, withStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators as ProductsActions } from '../../../store/ducks/products';

export const SearchTextField = withStyles({
  root: {
    background: 'white',
  },
})(TextField);

const SearchText = () => {
  const dispatch = useDispatch();
  const searchTerm = useSelector(state => state.products.searchTerm);

  const value = searchTerm !== '*' ? searchTerm : '';

  const onChange = (event) => {
    const newValue = event.target.value !== '' ? event.target.value : '*';

    dispatch(ProductsActions.setSearchTerm(newValue));
  };

  return (
    <SearchTextField
      value={value}
      onChange={onChange}
      placeholder="Digite o nome, código ou EAN da mercadoria"
      fullWidth
      variant="outlined"
      size="small"
    />
  );
};

export default SearchText;
