import React from 'react';
import PropTypes from 'prop-types';

function FixedCondition(props) {
  const { qtdDias, changeParcelamento, condicoes } = props;
  return (
    <select name="parcelamento" defaultValue={qtdDias || ''} onChange={(event) => { changeParcelamento(event.target.value); }}>
      <option value="">Selecione</option>
      {condicoes.map(
        condicao => (
          <option key={condicao.cdParcela} value={condicao.cdParcela}>
            {condicao.description}
          </option>
        ),
      )}
    </select>
  );
}

FixedCondition.propTypes = {
  qtdDias: PropTypes.string.isRequired,
  changeParcelamento: PropTypes.func.isRequired,
  condicoes: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    cdParcela: PropTypes.number.isRequired,
  })).isRequired,
};

export default FixedCondition;
