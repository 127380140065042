import { call, put } from 'redux-saga/effects';
import api from '../../services/api';

import { Creators as IndustriesActions } from '../ducks/industries';

export function* getIndustries() {
  try {
    let response = null;
    response = yield call(api.get, 'industries');

    if (response.status !== 200) {
      yield put(IndustriesActions.industryFailure('Problema ao buscar itens'));
    } else {
      yield put(IndustriesActions.industrySuccess(response.data));
    }
  } catch (err) {
    yield put(IndustriesActions.industryFailure('Problema ao buscar itens'));
  }
}
