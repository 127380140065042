import styled from 'styled-components';

import { Link } from 'react-router-dom';

import Primary from '../BtnPrimary';
import BtnNormal from '../Btn';

export const Container = styled.form``;

export const TotalsRow = styled.tr`
  td {
    font-weight: bold;
    padding-top: 5px;
  }
`;

export const LabelColumn = styled.td.attrs(() => ({
  colSpan: 5,
}))`
  text-align: right;
  color: #666;
`;

export const DelColumn = styled.td`
  width: 70px;
  text-align: center;
`;

export const BtnPrimary = styled(Primary)`
  margin-left: 40px;
`;

export const Btn = styled(BtnNormal)`
  margin-left: 15px;
`;

export const BtnContinueShopping = styled(Link)`
  background: #7eb9cd;
  border-radius: 15px;
  padding: 10px;
  color: #fff;
  border: 0;
  font-size: 15px;
  font-weight: bold;
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  text-decoration: none;
  margin-left: 15px;
`;
