import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 15px;
`;

export const ErrorList = styled.ul`
  border: 1px solid #cf7474;
  border-radius: 2px;
  list-style: none;
  list-style-position: inside;
  background-color: #efc2c2;

  li {
    margin: 10px;
  }
`;
