import { call, put } from 'redux-saga/effects';
import api from '../../services/api';
import { SOLAR_KEY, getClientData } from '../../services/auth';

import { Creators as ProductsActions } from '../ducks/products';

export function* getProducts(action) {
  try {
    let response = null;
    const isCampanhaSolar = (localStorage.getItem(SOLAR_KEY) === false || localStorage.getItem(SOLAR_KEY) === null) ? 'N' : 'S';
    const { cd_fabricante_pai: cdFabricantePai } = getClientData();
    let { payload } = action;

    payload = { ...payload, id_campanha_solar: isCampanhaSolar, cdFabricantePai };

    if (payload) {
      response = yield call(api.post, 'products', payload);
    } else {
      response = yield call(api.get, 'products');
    }

    if (response.status !== 200) {
      yield put(ProductsActions.productFailure('Problema ao buscar itens'));
    } else {
      yield put(ProductsActions.productSuccess(response.data));
    }
  } catch (err) {
    yield put(ProductsActions.productFailure('Problema ao buscar itens'));
  }
}
