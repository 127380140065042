import styled from 'styled-components';

import metrics from '../../styles/metrics';

export const Container = styled.div`
  display: flex;
  background: #213880;
  height: 80px;
  padding: ${metrics.baseMargin}px;
  justify-content: center;
  align-items: center;
`;

export const LogosContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NameContainer = styled.p`
  flex: 1;
  text-align: center;
  color: #fff;
`;
