import styled from 'styled-components';

import metrics from '../../../../styles/metrics';

export const ClockContainer = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: row;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 15px;
  padding: ${metrics.basePadding}px;
  color: #fff;
  font-size: 1.2em;
`;

export const ClockIcon = styled.div`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: ${props => (props.open ? '#0f0' : '#dd0')};
  margin-right: 10px;
`;
