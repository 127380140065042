import axios from 'axios';
import { getToken } from './auth';

export const baseURLAPI = process.env.REACT_APP_URL_API;

const api = axios.create({
  baseURL: `${baseURLAPI}api/v2/`,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  const newConfig = config;
  if (token) {
    newConfig.headers.Authorization = `Bearer ${token}`;
  }
  return newConfig;
});

export default api;
