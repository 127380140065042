import styled from 'styled-components';

export const Container = styled.button`
  background: #fff;
  color: #213880;

  :disabled {
    background: #eee;
    color: rgba(33, 56, 128, 0.5);
  }
`;
