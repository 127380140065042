import React, { Component } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";

import { Box, IconButton, Typography } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import ClearIcon from '@material-ui/icons/Clear';

import ModalContent from "../ModalContent";
import BloqueioIndicator from "./BloqueioIndicator";
import { ModalKitButton } from "./style";

export default class ProductName extends Component {
  state = {
    modalIsOpen: false,
    modalKitIsOpen: false,
    modalBloqueioIsOpen: false,
  };

  static propTypes = {
    product: PropTypes.shape({
      ds_apresentacao: PropTypes.string.isRequired,
      ds_mercadoria: PropTypes.string.isRequired,
      ean_venda_mercadoria: PropTypes.string.isRequired,
      url_imagem: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.bool.isRequired,
      ]),
      id_tipo_mercadoria: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      infos: PropTypes.shape({
        pc_desconto_financeiro: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]).isRequired,
        id_falta: PropTypes.string.isRequired,
      }).isRequired,
      bloqueios: PropTypes.shape({
        cd_conexao: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        cd_mercadoria: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        qt_limita_venda: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]).isRequired,
        qt_pedido_dia: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        qt_vendida: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
      }),
    }).isRequired,
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  openModalKit = () => {
    this.setState({ modalKitIsOpen: true });
  };

  openModalBloqueio = () => {
    this.setState({ modalBloqueioIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  closeModalKit = () => {
    this.setState({ modalKitIsOpen: false });
  };

  closeModalBloqueio = () => {
    this.setState({ modalBloqueioIsOpen: false });
  };

  render() {
    const { product } = this.props;
    const { modalIsOpen, modalKitIsOpen, modalBloqueioIsOpen } = this.state;
    const {
      id_tipo_mercadoria: idTipoMercadoria,
      infos: { pc_desconto_financeiro: pcDescontoFinanceiro },
    } = product;
    const hasFinancialDiscount = !(
      pcDescontoFinanceiro === 0 ||
      pcDescontoFinanceiro === "undefined" ||
      idTipoMercadoria.toString() !== "2"
    );

    return (
      <>
        {product.url_imagem && (
          <Modal
            isOpen={modalIsOpen}
            className="modal"
            onRequestClose={this.closeModal}
            contentLabel="Example Modal"
            style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
          >
            <div className="head">
              <h2>{product.ds_apresentacao}</h2>
              <IconButton onClick={this.closeModal} type="button">
                <ClearIcon />
              </IconButton>
            </div>
            <div className="content">
              <img src={product.url_imagem} alt={product.ds_apresentacao} />
              <p>
                <em>(Imagens meramente ilustrativas)</em>
              </p>
            </div>
          </Modal>
        )}
        <Modal
          isOpen={modalKitIsOpen}
          className="modal"
          onRequestClose={this.closeModalKit}
          contentLabel="Example Modal"
          style={{ overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" } }}
        >
          <div className="head">
            <h2>{product.ds_apresentacao}</h2>
            <IconButton onClick={this.closeModalKit} type="button">
              <ClearIcon />
            </IconButton>
          </div>
          <ModalContent product={product} />
        </Modal>

        <Box display="flex" alignItems="start" flexDirection="row">
          <Box>
            <IconButton
              onClick={this.openModal}
              disabled={!product.url_imagem}
              type="button"
            >
              <ImageIcon />
            </IconButton>
          </Box>
          <Box flex="1">
            { idTipoMercadoria.toString() === '-1' ? (
              <ModalKitButton type="button" onClick={this.openModalKit}>
                {product.ds_apresentacao}
              </ModalKitButton>
            ) : (
              <span>
                {product.ds_apresentacao}
                {product.bloqueio ? (
                  <BloqueioIndicator
                    product={product}
                    modalBloqueioIsOpen={modalBloqueioIsOpen}
                    closeModalBloqueio={this.closeModalBloqueio}
                    openModalBloqueio={this.openModalBloqueio}
                  />
                ) : null}
              </span>
            ) }
            { (product.infos.id_falta === 'S') && (
            <>
              <em className="out-of-stock"> (Em falta)</em>
            </>
            )}

            <br />
            <Typography component="span" variant="caption">{product.ean_venda_mercadoria}: {product.ds_mercadoria}</Typography>

            {hasFinancialDiscount && (
            <>
              <br />
              <em className="financial-discount">
                (Após a finalização do pedido você receberá{" "}
                {product.infos.pc_desconto_financeiro}% de desconto no boleto)
              </em>
            </>
            )}
          </Box>
        </Box>
      </>
    );
  }
}
