import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import sagas from './sagas';
import reducers from './ducks';

const middlewares = [];

const sagaMonitor = process.env.NODE_ENV === 'development' ? console.tron.createSagaMonitor() : null;
const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

middlewares.push(sagaMiddleware);

let storeTmp = null;
if (process.env.NODE_ENV === 'development') {
  storeTmp = createStore(
    reducers,
    compose(
      applyMiddleware(...middlewares),
      console.tron.createEnhancer(),
    ),
  );
} else {
  storeTmp = createStore(reducers, compose(applyMiddleware(...middlewares)));
}

sagaMiddleware.run(sagas);

const store = storeTmp;
export default store;
