import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { OrderImportContext } from './OrderImportContext';

const STATUS = {
  OUT_OF_STOCK: '⚠️ Mercadoria em falta',
  SUCCESS: '✅ Mercadoria adicionada com sucesso',
  NOT_FOUND: '⚠️ Mercadoria inexistente',
  SENDING: '🚀 Enviando...',
  WAITING: 'Aguardando',
};

export default function ItemsTable() {
  const { listItems } = useContext(OrderImportContext);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center" width={70}>#</TableCell>
            <TableCell align="center" width={150}>EAN</TableCell>
            <TableCell align="center" width={120}>Quantidade</TableCell>
            <TableCell align="center">Mercadoria</TableCell>
            <TableCell align="center" width={320}>Status</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {listItems.map((row, index) => (
            <TableRow key={row.ean}>
              <TableCell component="th" align="center">
                {index + 1}
              </TableCell>
              <TableCell align="center">{row.ean}</TableCell>
              <TableCell align="center">{row.amount}</TableCell>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="left">{STATUS[row.status]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
